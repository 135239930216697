import React from 'react';
import './CSS/loading.css';
import { Line } from "react-chartjs-2";


const graphOptions = {
	scales: {
			  y: {
				  title: {
					  display:true,
					  text:"Emissions (grams CO2)",
					  font: {
						  size: 14
					  }
				  }
			  },
			  x: {
				  title: {
					  display:true,
					  text:"Date",
					  font: {
						  size: 14
					  }
				  }
			  }        
		}
};

const findVariance = (arr = []) => {
   if(!arr.length){
      return 0;
   };
   const sum = arr.reduce((acc, val) => acc + val);
   const { length: num } = arr;
   const median = sum / num;
   let variance = 0;
   arr.forEach(num => {
      variance += ((num - median) * (num - median));
   });
   variance /= num;
   return variance;
};

// Function which automates the graphConfig generation
function getGraphConfig(graphData, graphPastData, graphLabel,graphColor) {
	let graphConfig = {
		datasets: [{ 
			label: graphLabel,
			data: graphPastData,
			labelColor: graphColor,
			borderColor: graphColor,
			backgroundColor: graphColor,
			pointBorderColor: "transparent",
			pointBackgroundColor: "transparent",
			tension: 0.2
		},{ 
			label: graphLabel+" Forecast",
			data: graphData,
			labelColor: graphColor,
			borderColor: graphColor,
			backgroundColor: graphColor,
			pointBorderColor: "transparent",
			pointBackgroundColor: "transparent",
			tension: 0.2,
			borderDash: [5,5]
		}]
	}
		return graphConfig
	};

function removeBelowZero(x){
	if (x < 0) {
		return 0;
	} else{
		return x;
	}
}

function zip(arr1,arr2,out={}){
    arr1.map( (val,idx)=>{ out[val] = arr2[idx]; } );
    return out;
}

const Forecast = ({state,update}) => {

	// Debugging purposes, prints the Forecast Response
	console.log(state)

	// If still training display status page. if not display graphs.
	if (state.Forecast.forecast.training_status === "training") {
			return (
				<div class="training-container">
					<img style={{width:"15rem",height:"auto"}} src="https://media.giphy.com/avatars/RobotHouse/rEsHr1nfaRCz.gif" alt=""/>
					<h1>Training In Progress</h1>
					<h2>Please Come Back Later</h2>
				</div>
			)
	} else if (state.Forecast.forecast.training_status === "trained") {

			var ec2ForecastEmissions = state.Forecast.forecast.ec2.p50.map(removeBelowZero);
			ec2ForecastEmissions = ec2ForecastEmissions.slice(0, ec2ForecastEmissions.length);

			var s3ForecastEmissions = state.Forecast.forecast.s3.p50.map(removeBelowZero);
			s3ForecastEmissions = s3ForecastEmissions.slice(0, s3ForecastEmissions.length/2);

			var ddbForecastEmissions = state.Forecast.forecast.ddb.p50.map(removeBelowZero);
			ddbForecastEmissions = ddbForecastEmissions.slice(0, ddbForecastEmissions.length/2);

			var rdsForecastEmissions = state.Forecast.forecast.rds.p50.map(removeBelowZero);
			rdsForecastEmissions = rdsForecastEmissions.slice(0, rdsForecastEmissions.length/2);

			var lambdaForecastEmissions = state.Forecast.forecast.lambda.p50.map(removeBelowZero);
			lambdaForecastEmissions = lambdaForecastEmissions.slice(0, lambdaForecastEmissions.length/2);

			var kinesisForecastEmissions = state.Forecast.forecast.kinesis.p50.map(removeBelowZero);
			kinesisForecastEmissions = kinesisForecastEmissions.slice(0, kinesisForecastEmissions.length/2);

			// Concat the forecast dates and 
			var forecastDates = state.Forecast.forecast.dates.slice(0, ec2ForecastEmissions.length/2);

			var pastDates = state.EC2.dates.slice(Math.max(state.EC2.dates.length - ec2ForecastEmissions.length/2, 0));

			var ec2PastEmissions = state.EC2.emissions.slice(Math.max(state.EC2.dates.length - ec2ForecastEmissions.length/2, 0));
			var s3PastEmissions = state.S3.emissions.slice(Math.max(state.EC2.dates.length - ec2ForecastEmissions.length/2, 0));
			var ddbPastEmissions = state.DDB.emissions.slice(Math.max(state.EC2.dates.length - ec2ForecastEmissions.length/2, 0));
			var rdsPastEmissions = state.RDS.emissions.slice(Math.max(state.EC2.dates.length - ec2ForecastEmissions.length/2, 0));
			var lambdaPastEmissions = state.Lambda.emissions.slice(Math.max(state.EC2.dates.length - ec2ForecastEmissions.length/2, 0));
			var kinesisPastEmissions = state.Kinesis.emissions.slice(Math.max(state.EC2.dates.length - ec2ForecastEmissions.length/2, 0));

			//Change from forecast dates to total dates
			var ec2GraphData = getGraphConfig(zip(forecastDates,ec2ForecastEmissions),zip(pastDates,ec2PastEmissions),"EC2","#6219c0");
			var s3GraphData = getGraphConfig(zip(forecastDates,s3ForecastEmissions),zip(pastDates,s3PastEmissions),"S3","#40c5ce");
			var ddbGraphData = getGraphConfig(zip(forecastDates,ddbForecastEmissions),zip(pastDates,ddbPastEmissions),"DynamoDB","#e4873b");
			var rdsGraphData = getGraphConfig(zip(forecastDates,rdsForecastEmissions),zip(pastDates,rdsPastEmissions),"RDS","#dfd111");
			var lambdaGraphData = getGraphConfig(zip(forecastDates,lambdaForecastEmissions),zip(pastDates,lambdaPastEmissions),"Lambda","#7c0436");
			var kinesisGraphData = getGraphConfig(zip(forecastDates,kinesisForecastEmissions),zip(pastDates,kinesisPastEmissions),"Kinesis","#ff05b4");
			var graphData = [ec2GraphData, s3GraphData, ddbGraphData, rdsGraphData, lambdaGraphData, kinesisGraphData]
			var graphCount = 0;

			return (
				<div class="forecast-container-wrapper">
					<h1 class="text-center">Month Forecast</h1>
					<h3 class="text-center lead">Below you will find the predicted emissions for one month.</h3>
					<div class="forecast-container">
						{graphData.map((data) => { 
							let varience_forecast = findVariance(Object.values(data.datasets[1].data).map(Number))
							let varience_past = findVariance(Object.values(data.datasets[0].data))
							var varience_ratio = varience_forecast / varience_past
							
							if ((varience_ratio > 10**(-4))) {
								graphCount += 1;
								return (
								<div class="card forecast-metric">
									<h5 class="forecast-graph-title">{data.datasets[0].label} Forecast</h5>
									<Line class="forecast-graph" options={graphOptions} data={data} width={"100%"} height={"50%"}/>
								</div>
							)}
						}
						)}	
					</div>
				</div>
			)
	} else {
			console.log("Server error");
			return (
				<div class="training-container">
					<img style={{width:"15rem",height:"auto"}} src="https://media.giphy.com/avatars/RobotHouse/rEsHr1nfaRCz.gif" alt=""/>
					<h1>Training In Progress</h1>
					<h2>Please Come Back Later</h2>
				</div>
			)
	}

};

export default Forecast;
