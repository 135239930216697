import React, { Component } from 'react';
import './CSS/navbar.css';

let changeMenu;
let menuStyle = 'bar';

class Navi extends Component{
    constructor(props) {
        super(props);
        this.state = {
            showLinks: false,
            setShowLinks: false
        }
    }
    
    handleFormPublic() {
        this.handleResetNav();
        this.props.update('publicHome', this.props.state.loggedIn);
    }
    
    handleFormSignUp() {
        this.handleResetNav();
        this.props.update('signUp', this.props.state.loggedIn);
    }
    
    handleFormSignIn() {
        this.handleResetNav();
        this.props.update('signIn', this.props.state.loggedIn);
    }

    handleFormQuickstart() {
        this.handleResetNav();
        this.props.update('quickStart', this.props.state.loggedIn);
    }

    handleFormEC2() {
        this.handleResetNav();
    
        this.props.update('ec2Emissions', this.props.state.loggedIn, 'EC2');
    }

    handleFormS3() {
        this.handleResetNav();
        this.props.update('S3Emissions', this.props.state.loggedIn, 'S3');
    }

    handleFormDynamo() {
        this.handleResetNav();
        
        this.props.update('dynamoEmissions', this.props.state.loggedIn, 'DynamoDB');
    }

    handleFormRDS() {
        this.handleResetNav();
        this.props.update('RDSEmissions', this.props.state.loggedIn, 'RDS');
    }

    handleFormLambda() {
        this.handleResetNav();
        this.props.update('LambdaEmissions', this.props.state.loggedIn, 'Lambda');
    }

    handleFormKinesis() {
        this.handleResetNav();
        this.props.update('KinesisEmissions', this.props.state.loggedIn, 'Kinesis');
    }

    handleFormDashboard() {
        this.handleResetNav();
        this.props.update('Dashboard', this.props.state.loggedIn);
    }

    handleFormGlobal() {
        this.handleResetNav();
        this.props.update('Global', this.props.state.loggedIn);
    }

    handleFormComparator() {
        this.handleResetNav();
        this.props.update('Comparator', this.props.state.loggedIn);
    }

    handleFormForecast() {
        this.handleResetNav();
        this.props.update('forecast', this.props.state.loggedIn);
    }

    handleFormLogout() {
        this.handleResetNav();
		localStorage.clear();
		this.props.clear();
    } 

    handleResetNav() {
        menuStyle = 'bar';
        this.setState({
            showLinks: false
        });
    }

    handleFormShowLinks() {
        if (this.state.showLinks === false) {
            changeMenu = !this.state.setShowLinks;
            menuStyle = 'barClose';
        } else {
            changeMenu = this.state.setShowLinks;
            menuStyle = 'bar';
        }
        
        this.setState({
            showLinks: changeMenu
        });
    }


    
    render () {
        if ((this.props.state.loggedIn === false)) {
            return (
                <nav class="navbar navbar-expand navbar-dark bg-navbar fixed-top" aria-label="Second navbar example">
                    <div class="container-fluid">
                        <a id='appTitle' class="navbar-brand cursor-pointer" onClick={event => { this.handleFormPublic(event) }}>ClimateCE</a><a class="navbar-brand cursor-pointer">  |   </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="/#navbarsExample02" aria-controls="navbarsExample02" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarsExample02">
                        <div class='links' id={this.state.showLinks ? "hidden" : "show"}>
                                <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a class="nav-link text-colour" onClick={event => {
                                    this.handleFormSignIn(event) 
                                }}>Sign In</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link text-colour" onClick={event => {
                                    this.handleFormQuickstart(event) 
                                }}>Quickstart</a>
                                </li>
                                </ul>
                            </div>
                        </div>
                        <div class='hamburger' onClick={event => {this.handleFormShowLinks(event)}}>
                        <span class={menuStyle}></span>
                        <span class={menuStyle}></span>
                        <span class={menuStyle}></span>
                    </div>
                    </div>
                </nav>
            ) 
        } else  {
            return (
                <nav class="navbar navbar-expand navbar-dark bg-navbar fixed-top" aria-label="Second navbar example">
                    <div class="container-fluid">
                    <a id='appTitle' class="navbar-brand cursor-pointer" onClick={event => { this.handleFormPublic(event) }}>ClimateCE</a><a class="navbar-brand cursor-pointer">  |   </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="/#navbarsExample02" aria-controls="navbarsExample02" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" >
                        <div class='links' id={this.state.showLinks ? "hidden" : "show"}>
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a class="nav-link text-colour" onClick={event => {this.handleFormDashboard(event)}}>Dashboard</a>
                                </li>
                                    <div class="dropdown">
                                        <button class="nav-link btn text-colour dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">Services</button> 
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li class="dropdown-item">
                                                <a class="nav-link text-colour" onClick={event => {this.handleFormEC2(event)}}>EC2</a>
                                            </li>
                                            <li class="dropdown-item">
                                                <a class="nav-link text-colour" onClick={event => {this.handleFormS3(event)}}>S3</a>
                                            </li>
                                            <li class="dropdown-item">
                                                <a class="nav-link text-colour" onClick={event => {this.handleFormDynamo(event)}}>DynamoDB</a>
                                            </li>
                                            <li class="dropdown-item">
                                                <a class="nav-link text-colour" onClick={event => {this.handleFormRDS(event)}}>RDS</a>
                                            </li>
                                            <li class="dropdown-item">
                                                <a class="nav-link text-colour" onClick={event => {this.handleFormLambda(event)}}>Lambda</a>
                                            </li>
                                            <li class="dropdown-item">
                                                <a class="nav-link text-colour" onClick={event => {this.handleFormKinesis(event)}}>Kinesis</a>
                                            </li>
                                        </ul>
                                    </div>
                                <li class="nav-item">
                                    <a class="nav-link text-colour" onClick={event => {this.handleFormGlobal(event)}}>Global</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link text-colour" onClick={event => {this.handleFormComparator(event)}}>Comparator</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link text-colour" onClick={event => {this.handleFormForecast(event)}}>Forecast</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link text-colour" onClick={event => {this.handleFormLogout(event)}}>Sign Out</a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class='hamburger' onClick={event => {this.handleFormShowLinks(event)}}>
                        <span class={menuStyle}></span>
                        <span class={menuStyle}></span>
                        <span class={menuStyle}></span>
                    </div>
                    </div>
                </nav>

            )
        }
    }
}
export default Navi;
