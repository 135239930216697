import { MapContainer, CircleMarker, TileLayer,Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import data from "./regions";


const unitCheck = (serviceTotal) => {
    let serviceTotalUnit;
    let unit = "g";
    if(serviceTotal>1000 && serviceTotal < 1000000) {
        serviceTotalUnit = (serviceTotal/1000);
        unit = 'kg'
    } else if(serviceTotal>1000000) {
        serviceTotalUnit = (serviceTotal/1000000);
        unit = 'tonne'
    } else {
        serviceTotalUnit = serviceTotal;
        unit = 'g'
    }
    serviceTotalUnit = serviceTotalUnit.toFixed(2);
    let response = []
    response = [{'num': serviceTotalUnit,'u': unit}]
    return response;
}


const sumValues = api_data_sum => Object.values(api_data_sum).reduce((a, b) => a + b);

const MapChart = ({state}) => {
 
  var api_data = state.Regions.emissions;
	var highest_emission = Math.max(...Object.values(api_data));
  var total_value = sumValues(api_data)

  return (
    <MapContainer style={{ height: "75vh", width: "100%" }} zoom={2.5} center={[30, 0]} >
		<TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

		{Object.keys(data).map((region) => { 
			let circle_color = 100 - parseInt(100*api_data[region]/highest_emission)
			//console.log(circle_color);
      let usage_number = unitCheck(api_data[region])[0]
      //console.log(typeof api_data)
      
      
      let percentage_values = ((api_data[region]/total_value)*100).toFixed(2)
      //console.log(percentage_values)

			return (
            <CircleMarker key={region} center={data[region].coords} radius={Math.log(api_data[region]/10)*3} fillOpacity={0.5} stroke={false} pathOptions={{ color: `hsl(${circle_color}, 100%, 39%)` }} >
              <Tooltip direction="right" offset={[-8, -2]} opacity={1}>
                <span><h6>{region}</h6></span>
                <span>{data[region].city + ": " + "Usage" + " " + percentage_values+"% "}</span>
              </Tooltip>
            </CircleMarker>
			)
		}
				)}
	</MapContainer>
  );
};

export default MapChart;
