import React from 'react';
import './CSS/loading.css';
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { Form } from 'react-bootstrap';

//SETTING DATE RANGE TO A 31 DAYS PRIOR (MONTHLY OVERVIEW)
const DASHBOARD_DAYS_BACK = 31;
var date = new Date();
date.setDate(date.getDate() - DASHBOARD_DAYS_BACK);
var start_date = date.toISOString().substring(0,10);

var date2 = new Date();
date2.setDate(date2.getDate() );
var end_date = date2.toISOString().substring(0,10);

var graphDataEC2, graphDataS3, graphDataRDS,graphDataDDB, graphDataLambda, graphDataKinesis, overallGraph, chartData= "";
var targetEmission = 0;
let sumS3, sumRDS, sumEC2, sumDDB, sumLambda, sumKinesis, sumTotal ;
let sumS3Dis, sumRDSDis, sumEC2Dis, sumDDBDis, sumLambdaDis, sumKinesisDis = [];
let unit = 'g'
let carbonCount = 0;    
let carbonMetric,totalImage = ''
//SETTING GRAPH AXIS LABELS
const graphOptions = {
	scales: {
			  y: {
				  title: {
					  display:true,
					  text:"Emissions (grams CO2)",
					  font: {
						  size: 14
					  }
				  }
			  },
			  x: {
				  title: {
					  display:true,
					  text:"Date",
					  font: {
						  size: 14
					  }
				  }
			  }        
		}
};

const graphOptionsBar = {
    indexAxis: 'y',
	scales: {
			  y: {
				  title: {
					  display:true,
					  text:"Services",
					  font: {
						  size: 14
					  }
				  }
			  },
			  x: {
				  title: {
					  display:true,
					  text:"Emissions",
					  font: {
						  size: 14
					  }
				  }
			  }        
		}
};

//CREATING HTML BLOCK FOR 6 SERVICE GRAPHS (TO REDUCE HTML CODE REPETITION) - props include data, title and pageLink
function ServiceMetric(props) {
	return (
			<article>
				<Line data={props.data} options={graphOptions} width={"100%"} height={"100%"} class="dashboard-chart"  />
			</article>
    );
}

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disView: 'Dashboard',
            graphType: 'overallEmissions',
            unitSize: 'scale',
            pieView: 'Percentage'

        };
        //INITIALLY SETTING targetEmission TO DAILY AVERAGE OVER 31 DAYS
        let target = (sumTotal)/DASHBOARD_DAYS_BACK;
        targetEmission = Array(DASHBOARD_DAYS_BACK+1).fill(target.toFixed(2));
        this.Comparison();
        this.processData();
    };

    //ALTERS THE UNIT TYPE ON METRICS IF THEY ARE UNSUITABLE UNDER g
    unitCheck(serviceTotal) {
        let serviceTotalUnit
        if(serviceTotal>1000 && serviceTotal < 1000000) {
            serviceTotalUnit = (serviceTotal/1000);
            unit = 'kg'
        } else if(serviceTotal>1000000) {
            serviceTotalUnit = (serviceTotal/1000000);
            unit = 'tonne'
        } else {
            serviceTotalUnit = serviceTotal;
            unit = 'g'
        }
        serviceTotalUnit = serviceTotalUnit.toFixed(2);
        let response = []
        response = [{'num': serviceTotalUnit,'u': unit}]
        return response;
    }

    //CREATING GRAPHS WITHOUT CODE REPETITION FOR THE 6 SERVICES
	getGraphConfig(graphDates, graphData, graphLabel, graphColor) {
        let graphConfig = {
					labels: graphDates,
					datasets: [{ 
						label: graphLabel,
						data: graphData,
						labelColor: graphColor,
						borderColor: graphColor,
						backgroundColor: graphColor,
						pointBorderColor: "#111111",
						pointBackgroundColor: "#FFFFFF",
						tension: 0.4
					}]
					}
		return graphConfig
	};

	processData() {
        //HAVING DATA RANGE DISPLAYED SPECIFIC TO THE LAST 31 DAYS
        let monthSearchStart = (this.props.state.EC2['dates'].indexOf(start_date));
        let monthSearchEnd = (this.props.state.EC2['dates'].indexOf(end_date));
		
		sumS3 = sumRDS = sumEC2= sumDDB = sumLambda = sumKinesis = sumTotal = 0;

        //POPULATING VARIABLES TO REDUCE CODE CLUTTERING
        //BELOW THE EMISSION DATA CORRESPONDING TO THE RESPECTIVE METRICS IS POPULATED
        let EC2Emissions = this.props.state.EC2["emissions"].slice(monthSearchStart, monthSearchEnd);
        let S3Emissions = this.props.state.S3["emissions"].slice(monthSearchStart, monthSearchEnd);
        let DDBEmissions = this.props.state.DDB["emissions"].slice(monthSearchStart, monthSearchEnd);
        let RDSEmissions = this.props.state.RDS["emissions"].slice(monthSearchStart, monthSearchEnd);
        let LambdaEmissions = this.props.state.Lambda["emissions"].slice(monthSearchStart, monthSearchEnd);
        let KinesisEmissions = this.props.state.Kinesis["emissions"].slice(monthSearchStart, monthSearchEnd);
        
        //BELOW THE DATES OF DATA ENTRIES THE RESPECTIVE EMISSION DATA IS POPULATED
        let EC2Dates = this.props.state.EC2["dates"].slice(monthSearchStart, monthSearchEnd);
        let S3Dates = this.props.state.S3["dates"].slice(monthSearchStart, monthSearchEnd);
        let DDBDates = this.props.state.DDB["dates"].slice(monthSearchStart, monthSearchEnd);
        let RDSDates = this.props.state.RDS["dates"].slice(monthSearchStart, monthSearchEnd);
        let LambdaDates = this.props.state.Lambda["dates"].slice(monthSearchStart, monthSearchEnd);
        let KinesisDates = this.props.state.Kinesis["dates"].slice(monthSearchStart, monthSearchEnd);

        //SUM OF THE MONTHLY EMISSION PER SERVICE IS CALCULATED BELOW
        for (let i=0;i<EC2Emissions.length;i++) {
            sumEC2 += EC2Emissions[i];
            sumS3 += S3Emissions[i];
            sumDDB += DDBEmissions[i];
            sumRDS += RDSEmissions[i];
            sumLambda += LambdaEmissions[i];
            sumKinesis += KinesisEmissions[i];
            //sumTotal IS THEN USED FOR AN AVERAGE VALUE WHICH IS THE INITIAL TARGET VALUE
			sumTotal += EC2Emissions[i] + S3Emissions[i] + DDBEmissions[i] + RDSEmissions[i] + LambdaEmissions[i] + KinesisEmissions[i]
        };
    
        //CHANGING DISPLAYED DATA BASED ON SCALE SELECT FROM USER
        if (this.state.unitSize === 'scale') {
            sumEC2Dis = this.unitCheck(sumEC2)
            sumS3Dis = this.unitCheck(sumS3)
            sumDDBDis = this.unitCheck(sumDDB)
            sumRDSDis = this.unitCheck(sumRDS)
            sumLambdaDis = this.unitCheck(sumLambda)
            sumKinesisDis = this.unitCheck(sumKinesis)
        } else {
            unit = 'g'
            sumEC2Dis =  [{'num': sumEC2.toFixed(2),'u': unit}]
            sumS3Dis =  [{'num': sumS3.toFixed(2),'u': unit}]
            sumDDBDis =  [{'num': sumDDB.toFixed(2),'u': unit}]
            sumRDSDis =  [{'num': sumRDS.toFixed(2),'u': unit}]
            sumLambdaDis =  [{'num': sumLambda.toFixed(2),'u': unit}]
            sumKinesisDis =  [{'num': sumKinesis.toFixed(2),'u': unit}]
        }


        //BELOW THE 6 SERVICE GRAPHS ARE POPULATED WITH THEIR CORRESPONDING DATA - TITLE AND INDIVIDUAL COLOUR
		graphDataEC2 = this.getGraphConfig(EC2Dates,EC2Emissions,"EC2 Emissions","#6219c0");
		graphDataS3 = this.getGraphConfig(S3Dates,S3Emissions,"S3 Emissions","#40c5ce");
		graphDataDDB = this.getGraphConfig(DDBDates,DDBEmissions,"DynamoDB Emissions","#e4873b");
		graphDataRDS = this.getGraphConfig(RDSDates,RDSEmissions,"RDS Emissions","#dfd111");
		graphDataLambda = this.getGraphConfig(LambdaDates ,LambdaEmissions,"Lambda Emissions","#7c0436");
		graphDataKinesis = this.getGraphConfig(KinesisDates,KinesisEmissions,"Kinesis Emissions","#ff05b4");

        //IF THE serviceOverview OPTION IS SELECTED THE overallGraph IS SET TO DISPLAY EACH SERVICE CONTRIBUTION OVER THE LAST 31 DAYS
        if (this.state.graphType === 'servicesOverview') {
            overallGraph = {
                labels: this.props.state.EC2["dates"].slice(monthSearchStart, monthSearchEnd),
				datasets: [{ 
						label: "EC2",
						data: EC2Emissions,
						labelColor: '#6219c0',
						borderColor: "#6219c0",
						backgroundColor: "#6219c0",
						pointBorderColor: "#111111",
						pointBackgroundColor: "#FFFFFF",
						tension: 0.4,
                        pointRadius: 2
					},
					{
						label: "S3",
						data: S3Emissions,
						labelColor: '#40c5ce',
						borderColor: "#40c5ce",
						backgroundColor: "#40c5ce",
						pointBorderColor: "#111111",
						pointBackgroundColor: "#FFFFFF",
						tension: 0.4,
                        pointRadius: 2
					},
					{
						label: "DynamoDB",
						data: DDBEmissions,
						labelColor: '#e4873b',
						borderColor: "#e4873b",
						backgroundColor: "#e4873b",
						pointBorderColor: "#111111",
						pointBackgroundColor: "#FFFFFF",
						tension: 0.4,
                        pointRadius: 2
					},
					{
						label: "RDS",
						data: RDSEmissions,
						labelColor: '#dfd111',
						borderColor: "#dfd111",
						backgroundColor: "#dfd111",
						pointBorderColor: "#111111",
						pointBackgroundColor: "#FFFFFF",
						tension: 0.4,
                        pointRadius: 2
					},
					{
						label: "Lambda",
						data: LambdaEmissions,
						labelColor: '#7c0436',
						borderColor: "#7c0436",
						backgroundColor: "#7c0436",
						pointBorderColor: "#111111",
						pointBackgroundColor: "#FFFFFF",
						tension: 0.4,
                        pointRadius: 2
					},
					{
						label: "Kinesis",
						data: KinesisEmissions,
						labelColor: '#ff05b4',
						borderColor: "#ff05b4",
						backgroundColor: "#ff05b4",
						pointBorderColor: "#111111",
						pointBackgroundColor: "#FFFFFF",
						tension: 0.4,
                        pointRadius: 2
					},
					{
						label: "Target Emission Level",
						data: targetEmission,
						labelColor: 'red',
						borderColor: "red",
						backgroundColor: "red",
						pointBorderColor: "#111111",
						pointBackgroundColor: "#FFFFFF",
						pointRadius:0
					},
					]
            };
        }else{
            //ALTERNATIVELY, THE overallEmissions OPTION WILL DISPLAY THE DAILY SERVICE TOTAL CONTRIBUTION
            let overallEmissions = [];
            for (let i=0;i<this.props.state.EC2["emissions"].slice(monthSearchStart, monthSearchEnd).length;i++) {
                overallEmissions.push(parseInt(this.props.state.EC2['emissions'].slice(monthSearchStart, monthSearchEnd)[i]) + parseInt(this.props.state.S3['emissions'].slice(monthSearchStart, monthSearchEnd)[i]) + parseInt(this.props.state.DDB['emissions'].slice(monthSearchStart, monthSearchEnd)[i]) + parseInt(this.props.state.RDS['emissions'].slice(monthSearchStart, monthSearchEnd)[i]) + parseInt(this.props.state.Lambda['emissions'].slice(monthSearchStart, monthSearchEnd)[i]) + parseInt(this.props.state.Kinesis['emissions'].slice(monthSearchStart, monthSearchEnd)[i]));
            };
            overallGraph = {
                labels: this.props.state.EC2["dates"].slice(monthSearchStart, monthSearchEnd),
                scales: {
                    _yScale: [{
                        min: 0,
                        max: 2000
                    }]
                },
                datasets: [
                    {
                    label: "Daily Emissions",
                    data: overallEmissions,
                    labelColor: '#4ec443',
                    borderColor: "#4ec443",
                    backgroundColor: "#4ec443",
                    pointBorderColor: "#111111",
                    pointBackgroundColor: "#FFFFFF",
                    tension: 0.4
                },
                {
                    label: "Target Emission Level",
                    data: targetEmission,
                    labelColor: 'red',
                    borderColor: "red",
                    backgroundColor: "red",
                    pointBorderColor: "#111111",
                    pointBackgroundColor: "#FFFFFF",
                    pointRadius:0
                },
                ]
            };
        }
    
        //POPULATING THE PIE CHART DISPLAY

        //CHANGING PIE CHART DATA BASED ON USER CHOICE OF NUMBER OR PERCENTAGE
        let dataSet = []
        if (this.state.pieView === 'Percentage') {
            dataSet.push((sumEC2/sumTotal)*100, (sumS3/sumTotal)*100, (sumDDB/sumTotal)*100, (sumRDS/sumTotal)*100, (sumLambda/sumTotal)*100, (sumKinesis/sumTotal)*100)
        } else {
            dataSet.push(sumEC2,sumS3, sumDDB, sumRDS, sumLambda, sumKinesis); 
        }

        chartData = {
            labels: ['EC2','S3','DDB', 'RDS', 'Lambda', 'Kinesis'],
            scales: {
                _xScale: [{
                    min: 0,
                    max: 2000
                }]
            },
            datasets: [{
                label: 'Services',
                data: dataSet,
                labelColor: '#000000',
                backgroundColor: ["#6219c0", "#40c5ca","#e4873b","#dfd111", "#7c0436","#ff05b4"],
                borderColor:'#FFFFFF',
                pointBorderColor: "#111111",
                pointBackgroundColor: "#FFFFFF",
            }
            ]
        };

        //ONCE DISPLAYS ARE READY THE PAGE IS UPDATED TO THEN RENDER IN THE GRAPH PAGE
        this.setState({
            disView: 'Dashboard'
        });
    }

    //WHEN GRAPH TYPE IS CHANGED THE STATE IS CHANGED AND SO THE DISPLAY WILL UPDATE ACCORDINGLY
    handleFormSubmitDisplay() {
        this.state.graphType = document.getElementById('graphType').value;
        this.processData()
    }     
 
    //NAVIGATION TO INDIVIDUAL PAGES
    handleFormEC2(event) {
        event.preventDefault();
        this.props.update('ec2Emissions', this.props.state.loggedIn, 'EC2');
    }

    handleFormS3(event) {
        event.preventDefault();
        this.props.update('S3Emissions', this.props.state.loggedIn, 'S3');
    }

    handleFormDynamo(event) {
        event.preventDefault();
        this.props.update('dynamoEmissions', this.props.state.loggedIn, 'DynamoDB');
    }

    handleFormRDS(event) {
        event.preventDefault();
        this.props.update('RDSEmissions', this.props.state.loggedIn, 'RDS');
    }

    handleFormLambda(event) {
        event.preventDefault();
        this.props.update('LambdaEmissions', this.props.state.loggedIn, 'Lambda');
    }

    handleFormKinesis(event) {
        event.preventDefault();
        this.props.update('KinesisEmissions', this.props.state.loggedIn, 'Kinesis');
    }

    handleFormComparator(event) {
        event.preventDefault();
        this.props.update('Comparator', this.props.state.loggedIn);
    }

    //UPDATING TARGET VALUE ON GRAPH
    handleUpdateTarget(){
        if (document.getElementById('targetEmission').value > 0) {
            this.setState({
                disView: 'load'
            });
            let target = document.getElementById('targetEmission').value;
            targetEmission = Array(DASHBOARD_DAYS_BACK).fill(target);
        }
        this.processData();
    }

    //SETS semXDis VALUES TO SCALE ACCORDING TO INPUT FROM USER
    handleScaleValues(e) {
        e.preventDefault();
        if (this.state.unitSize === 'scale') {
            sumEC2Dis[0]['num'] = sumEC2
            sumS3Dis[0]['num'] = sumS3
            sumDDBDis[0]['num'] = sumDDB
            sumRDSDis[0]['num'] = sumRDS
            sumLambdaDis[0]['num'] = sumLambda
            sumKinesisDis[0]['num'] = sumKinesis
            this.state.unitSize = 'unscale'
        } else {
            sumEC2Dis = []
            sumS3Dis = []
            sumDDBDis = []
            sumRDSDis = []
            sumLambdaDis = []
            sumKinesisDis = []
            this.state.unitSize = 'scale'
        }
        this.processData();
    }

    //CHANGES PIE CHART VALUE FROM TOTAL SUM TO PERCENTAGE
    handlePieUnit(e) {
        e.preventDefault();
        if (this.state.pieView === 'Total Sum') {
            this.state.pieView = 'Percentage'
        } else {                 
            this.state.pieView = 'Total Sum'
        }
        this.processData();
    }

    //WORK OUT WHICH COMPARISON THIS WOULD COME UNDER AND UPDATE IMAGE ON DISPLAY
    Comparison() {   
        carbonCount = 0;    
        carbonMetric = this.props.state.Comparator['metric'];
        var temp = this.props.state.Comparator['value'];
        carbonCount = temp.slice(0, temp.indexOf('.')+3)
        if (carbonMetric === 'cats') {
            totalImage = "http://pngimg.com/uploads/cat/cat_PNG50432.png";//require("../static/cat.png").default;
        } else if (carbonMetric === 'watermelon') {
            totalImage = "http://pngimg.com/uploads/watermelon/watermelon_PNG234.png";//require("../static/watermelon.png").default;
        } else if (carbonMetric === 'grand') {
            carbonMetric = 'grand piano';
            totalImage = "https://www.freepnglogos.com/uploads/piano-png/piano-photo-francomac-photobucket-1.png";//require("../static/piano.png").default;
        } else if (carbonMetric === 'baby') {
            carbonMetric = 'baby elephant';
            totalImage = "https://i.imgur.com/0kpCHKG.png";//require("../static/elephant.png").default;
        } else if (carbonMetric === 'car') {
            totalImage = "https://freepngimg.com/download/volkswagen/31-red-old-volkswagen-beetle-png-car-image.png";//require("../static/car.png").default;
        } else if (carbonMetric === 'firetruck') {
            totalImage = "https://www.pngall.com/wp-content/uploads/4/Fire-Truck-PNG-Free-Download.png";//require("../static/truck.png").default;
        } else if (carbonMetric === 'spaceshuttle') { 
            carbonMetric = 'space shuttle';
            totalImage = "https://i.imgur.com/C1GAenW.png";//require("../static/whale.png").default;
        } else if (carbonMetric === 'eiffel') {
            carbonMetric = 'eiffel tower';
            totalImage = "https://pngimg.com/uploads/eiffel_tower/eiffel_tower_PNG38.png";//require("../static/tower.png").default;
        }
    }

    //RENDERING HTML
    render () {
        if (this.state.disView === 'Dashboard' ) {
            return (
                <main class="dashboard-container">   
					<div class="dashboard-main-title">
						<h1 class="fw-light text-center"><b>Welcome {this.props.state.email},</b></h1>
						<h1 class="lead text-center">{new Date().toDateString()}</h1>
					</div>
					<div class="card">
					    <h3 class="dashboard-metric-title text-center">This Month's Daily Carbon Emissions</h3>
                        <div class="overall-container">
                            <div class="dashboard-overall-chart">
                                <article>
                                    <Line data={overallGraph} options={graphOptions} width={"100%"} height={"100%"} class="overall-aspect-ratio"  />
                                </article>
                            </div>
                                <div class="dashboard-metrics" id='dashboard-graphOptions' >
                                    <div class="card shadow-sm graph-constraints"> 
                                            <div class="card-body">
                                                <div class="overall-monthly-emissions" >
                                                    <h5 class="text-center">This Month's Service Emissions</h5><hr/>
                                                    <table class="table">
                                                    <thead class="thead-dark">
                                                        <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Service</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope='col'>Unit / (CO<sub>2</sub>)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                        <th scope="row">1</th>
                                                        <td>EC2</td>
                                                        <td>{sumEC2Dis[0]['num']} </td>
                                                        <td><b>{sumEC2Dis[0]['u']}</b></td>
                                                        </tr>
                                                        <tr>
                                                        <th scope="row">2</th>
                                                        <td>S3</td>
                                                        <td>{sumS3Dis[0]['num']} </td>
                                                        <td> <b>{sumS3Dis[0]['u']}</b></td>
                                                        </tr>
                                                        <tr>
                                                        <th scope="row">3</th>
                                                        <td>DynamoDB</td>
                                                        <td>{sumDDBDis[0]['num']} </td>
                                                        <td> <b>{sumDDBDis[0]['u']}</b></td>
                                                        </tr>
                                                        <tr>
                                                        <th scope="row">4</th>
                                                        <td>RDS</td>
                                                        <td>{sumRDSDis[0]['num']}  </td>
                                                        <td><b>{sumRDSDis[0]['u']}</b></td>
                                                        </tr>
                                                        <tr>
                                                        <th scope="row">5</th>
                                                        <td>Lambda</td>
                                                        <td>{sumLambdaDis[0]['num']}  </td>
                                                        <td><b>{sumLambdaDis[0]['u']}</b></td>
                                                        </tr>
                                                        <tr>
                                                        <th scope="row">6</th>
                                                        <td>Kinesis</td>
                                                        <td>{sumKinesisDis[0]['num']}  </td>
                                                        <td><b>{sumKinesisDis[0]['u']}</b></td>
                                                        </tr>
                                                    </tbody>
                                                    </table>
                                                    <button id="scaleUnits" type="button" class="btn btn-outline-success" onClick={event => this.handleScaleValues(event)}>Scale</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card shadow-sm graph-constraints">
                                            <div class="card-body">
                                                <h5 class="text-center">Update Target Value</h5><hr/>
                                                <p><b>Current Target: {targetEmission[0]}g CO<sub>2</sub></b></p>
                                                <label>Target Emission: </label>
                                                <div class="input-group input-group-sm mb-3">
                                                    <input placeholder={targetEmission[0]}  type='text' inputMode='numeric' pattern="[0-9]*" id='targetEmission' class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                                                    <button class="btn btn-outline-success" type="button" id="button-addon2" onClick={event => {this.handleUpdateTarget(event)}}>Update</button>
                                                </div>
                                                <div class="input-group-prepend">
                                                <label>Data display: </label>
                                                    <div class="input-group input-group-sm mb-3">
                                                        <Form.Select id='graphType' >  
                                                            <option value='overallEmissions'>Overall Emissions</option>										
                                                            <option value='servicesOverview'>Services Overview</option>
                                                        </Form.Select>
                                                        <button type="button" class="btn btn-outline-success" onClick={event => {this.handleFormSubmitDisplay(event)}}>Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
                    <div class="card">
                        <h3 class="dashboard-metric-title text-center">This Month's Service Emissions</h3>
                        <div class="dashboard-metrics">
                            <div class="card shadow-sm dashboard-metric">
                                <ServiceMetric data={graphDataEC2}/>
                                <div class=" dashboard-metric-items">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-outline-secondary" onClick={event => this.handleFormEC2(event)} >View More</button>
                                    </div>
                                    <small class="text-muted">EC2 Emissions</small>
                                </div>
                            </div>
                            <div class="card shadow-sm dashboard-metric">
                                <ServiceMetric data={graphDataS3}/>
                                <div class=" dashboard-metric-items">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-outline-secondary" onClick={event => this.handleFormS3(event)}  >View More</button>
                                    </div>
                                    <small class="text-muted">S3 Emissions</small>
                                </div>
                            </div>
                            <div class="card shadow-sm dashboard-metric">
                                <ServiceMetric data={graphDataDDB}/>
                                <div class=" dashboard-metric-items">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-outline-secondary" onClick={event => this.handleFormDynamo(event)} >View More</button>
                                    </div>
                                    <small class="text-muted">DynamoDB Emissions</small>
                                </div>
                            </div>
                            <div class="card shadow-sm dashboard-metric">
                                <ServiceMetric data={graphDataRDS}/>
                                <div class=" dashboard-metric-items">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-outline-secondary" onClick={event => this.handleFormRDS(event)} >View More</button>
                                    </div>
                                    <small class="text-muted">RDS Emissions</small>
                                </div>
                            </div>
                            <div class="card shadow-sm dashboard-metric">
                                <ServiceMetric data={graphDataLambda}/>
                                <div class=" dashboard-metric-items">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-outline-secondary" onClick={event => this.handleFormLambda(event)} >View More</button>
                                    </div>
                                    <small class="text-muted">Lambda Emissions</small>
                                </div>
                            </div>
                            <div class="card shadow-sm dashboard-metric">
                                <ServiceMetric data={graphDataKinesis}/>
                                <div class=" dashboard-metric-items">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-outline-secondary" onClick={event => this.handleFormKinesis(event)} >View More</button>
                                    </div>
                                    <small class="text-muted">Kinesis Emissions</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <h3 class="dashboard-metric-title text-center">This Month's Emission Breakdown</h3>
                        <div class="overall-container">
                            <div class="dashboard-metrics" id='dashboard-graphOptions' >
                                <div class="card shadow-sm dashboard-metric">
                                    <div class="card-body">
                                        <div class="overall-monthly-emissions" >
                                            <h3 class="dashboard-metric-title text-center">Service Emission Comparison</h3>
                                            <p style={{textAlign:'center'}}>Find which service is producing the most emissions.</p>
                                            <article>
                                                <Bar data={chartData} options={graphOptionsBar} width={"100%"} height={"100%"} class="dashboard-chart" />
                                            </article>
											<p style={{textAlign:'center'}}>Graph Data View: <button id="pieUnits" type="button" class="btn btn-outline-success" onClick={event => this.handlePieUnit(event)}>{this.state.pieView}</button></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card shadow-sm dashboard-metric">
                                    <div class="card-body">
                                        <div class="overall-monthly-emissions text-center" >
                                            <h3 class="dashboard-metric-title ">Emission Comparator</h3> 
                                            <p style={{textAlign:'center'}}>Review how your emissions compare to other everyday objects!</p>
                                            <h5>Annual emission comparison:</h5>
                                                <p><b>{carbonCount} of a {carbonMetric} !</b></p>
                                                <p><img style={{textAlign:'center',width: '10rem'}} src={totalImage} alt='/#'/></p><br/>
                                            <div class=" justify-content-between ">
                                                    <button type="button" class="btn btn-sm btn-outline-secondary" onClick={event => {this.handleFormComparator(event)}}>View More</button>
                                            </div>
                                        </div>
                                    </div>                                                                                             
                                </div>
                            </div>
                        </div>
                    </div>
            </main>     )
        } else if (this.state.disView === 'load') {
            return (
                <div class={"Loading"}>
                <p>Loading</p>
                    <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div> 
                </div>
            )
        }
    }
}

export default Dashboard;
