import React from "react";
import SignUpForm from "./SignUpForm"

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disView: "signUp"
        }

    }
    
    render() {
		return (
			<SignUpForm  update={this.props.update} state={this.state}/>
		)
    }
}
export default SignUp;
