import './App.css';
import React from 'react';
import './components/CSS/loading.css';
import './components/CSS/Register.css';
import VerifyUser from './components/VerifyUser';
import SignUp from './components/SignUp';
import Forecast from './components/Forecast';
import SignIn from './components/SignIn';
import Navi from './components/Navi';
import PublicHome from "./components/PublicHome";
import EC2Emissions from './components/EC2Emissions';
import DynamoDB from './components/DynamoDB';
import Dashboard from './components/Dashboard';
import S3Emissions from './components/S3Emissions';
import RDSEmissions from './components/RDSEmissions';
import LambdaEmissions from './components/LambdaEmissions';
import KinesisEmissions from './components/KinesisEmissions';
import Summary from './components/Summary';
import Global from './components/Global';
import Quickstart from './components/Quickstart';
import Comparator from './components/Comparator';
import UserPool from "./UserPool";

var url = "https://api.climatece.co.uk/"
var token = "?token=7a197192-a2d1-400b-9de2-dfcf735d48b2"

var date = new Date();
//setting up a 'live date' of 1 year ago to display
date.setDate(date.getDate() - 355);
var start_date = date.toISOString().substring(0, 10);

var date2 = new Date();
date2.setDate(date2.getDate());
var end_date = date2.toISOString().substring(0, 10);

var response = ''

// Start page for user, public page
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disView: 'publicHome',
      loggedIn: false,
      serviceChoice: '',
      EC2: [],
      S3: [],
      DDB: [],
      RDS: [],
      Lambda: [],
      Kinesis: [],
      access_key: '',
      secret_key: '',
      email: ''
    };

    this.stateUpdate = this.stateUpdate.bind(this);
    this.setState = this.setState.bind(this);
    this.userCheck = this.userCheck.bind(this);
    this.clearState = this.clearState.bind(this);
  }

  componentDidMount() {
    this.userCheck();
  }

  stateUpdate(view, loggedIn, service) {
    this.setState({
      disView: view,
      loggedIn: loggedIn,
      serviceChoice: service
    })
  }

  clearState() {
    this.setState({
      disView: 'publicHome',
      loggedIn: false,
      serviceChoice: '',
      EC2: [],
      S3: [],
      DDB: [],
      RDS: [],
      Lambda: [],
      Kinesis: [],
      access_key: '',
      secret_key: '',
      email: ''
    }
    )
  }

  //CHECKS WHETHER THE USER REMAINS LOGGED IN AFTER REFRESH
  async userCheck() {

    var user = UserPool.getCurrentUser();

    if (user !== null) {

      this.setState({ disView: "load", loggedIn: false });

      //move this into constructor of App.js
      this.sessionInfo = user.getSession((err, session) => {
        if (err) {
          console.log(err);
        } else {
          return session.idToken.payload;
        }
      });

      let user_access_key = this.sessionInfo["custom:access_key"];
      let user_secret_key = this.sessionInfo["custom:secret_key"];
      let user_email = this.sessionInfo["email"];

      this.payload = {
        "access_key": user_access_key,
        "secret_key": user_secret_key,
        "start_date": start_date,
        "end_date": end_date
      }

      this.setState({ email: user_email, access_key: user_access_key, secret_key: user_secret_key });

      this.searchData();

    } else if (user == null) {
      this.setState({ disView: "publicHome", loggedIn: false });
    }
  }

  async fetchData() {
    //parameters = url + service + token;
    const apiRequest = {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.payload)
    };
    await Promise.all([
      fetch(url + 'ec2-emissions' + token, apiRequest),
      fetch(url + 's3-emissions' + token, apiRequest),
      fetch(url + 'ddb-emissions' + token, apiRequest),
      fetch(url + 'rds-emissions' + token, apiRequest),
      fetch(url + 'lambda-emissions' + token, apiRequest),
      fetch(url + 'kinesis-emissions' + token, apiRequest),
      fetch(url + 'regions-usage' + token, apiRequest),
      fetch(url + 'forecasting-metrics' + token, apiRequest),
      fetch(url + 'frontend_metrics' + token, apiRequest)
    ]).then(function (responses) {
      return Promise.all(responses.map(function (response) {
        return response.json();
      }));
    }).then(data => response = data
    ).catch(function (error) {
      // if there's an error, log it
      console.log('Fetch error:' + error);
    });
    console.log(response)
    return response
  }

  async searchData() {
    const res = await this.fetchData();
    console.log(res);
    this.setState({
      disView: 'Dashboard',
      loggedIn: true,
      EC2: res[0],
      S3: res[1],
      DDB: res[2],
      RDS: res[3],
      Lambda: res[4],
      Kinesis: res[5],
			Regions: res[6],
			Forecast: res[7],
      Comparator: res[8]
    })
    console.log(this.state);
  }

  render() {
    if (this.state.disView === 'publicHome') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} clear={this.clearState} />
          </div>
          <PublicHome update={this.stateUpdate} state={this.state} />
        </div>
      );
    } else if (this.state.disView === 'quickStart') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} />
          </div>
          <div>
            <Quickstart update={this.stateUpdate} state={this.state} />
          </div>
        </div>
      );
    } else if (this.state.disView === 'signUp') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} />
          </div>
          <div>
            <SignUp update={this.stateUpdate} state={this.state} />
          </div>
        </div>
      );
    } else if (this.state.disView === 'signIn') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} />
          </div>
          <div>
            <SignIn update={this.stateUpdate} state={this.state} checkUser={this.userCheck} />
          </div>
        </div>
      );
    }
    else if (this.state.disView === 'verifyUser') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} />
          </div>
          <div>
            <VerifyUser update={this.stateUpdate} state={this.state} checkUser={this.userCheck} />
          </div>
        </div>
      )
    } else if (this.state.disView === 'ec2Emissions') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} clear={this.clearState} />
          </div>
          <EC2Emissions update={this.stateUpdate} state={this.state} />
        </div>
      );
    } else if (this.state.disView === 'dynamoEmissions') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} clear={this.clearState} />
          </div>
          <DynamoDB update={this.stateUpdate} state={this.state} />
        </div>
      );
    } else if (this.state.disView === 'S3Emissions') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} clear={this.clearState} />
          </div>
          <S3Emissions update={this.stateUpdate} state={this.state} />
        </div>
      );
    } else if (this.state.disView === 'RDSEmissions') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} clear={this.clearState} />
          </div>
          <RDSEmissions update={this.stateUpdate} state={this.state} />
        </div>
      );
    } else if (this.state.disView === 'LambdaEmissions') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} clear={this.clearState} />
          </div>
          <LambdaEmissions update={this.stateUpdate} state={this.state} />
        </div>
      );
    } else if (this.state.disView === 'KinesisEmissions') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} clear={this.clearState} />
          </div>
          <KinesisEmissions update={this.stateUpdate} state={this.state} />
        </div>
      );
    } else if (this.state.disView === 'Summary') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} clear={this.clearState} />
          </div>
          <Summary update={this.stateUpdate} state={this.state} />
        </div>
      );
    }else if (this.state.disView === 'Global') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} clear={this.clearState} />
          </div>
          <Global update={this.stateUpdate} state={this.state} />
        </div>
      );
    } else if (this.state.disView === 'Comparator') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} clear={this.clearState} />
          </div>
          <Comparator update={this.stateUpdate} state={this.state} />
        </div>
      );
    } else if (this.state.disView === 'forecast') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} clear={this.clearState} />
          </div>
          <Forecast update={this.stateUpdate} state={this.state} />
        </div>
      );
    } else if (this.state.disView === 'Dashboard') {
      return (
        <div>
          <div>
            <Navi update={this.stateUpdate} state={this.state} clear={this.clearState} />
          </div>
          <Dashboard update={this.stateUpdate} state={this.state} />
        </div>
      );
    } else if (this.state.disView === 'load') {
      return (
        <div>
          <div class={"back"}>
            <div class={"div-center"}>
              <br />
              <h3 style={{ textAlign: 'center' }}>Welcome, {this.state.email}!</h3>
            </div>
          </div>
          <div class={"Loading"}>

            <h5 class='loadingText'>Loading</h5>
            <div class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        </div>
      )
    }

  }

}


export default App;
