import React from "react";
import SignInForm from "./SignInForm"

/**
 * SignIn class that renders a sign-in form for the user
 */
class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disView: "signIn" }
    }
    
    render() {
		return (
			<SignInForm update={this.props.update} checkUser={this.props.checkUser} state={this.state}/>
		)
    }
}
export default SignIn;
