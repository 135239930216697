import React from 'react';
import './CSS/loading.css';
import {ListGroup} from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { Bar, Line } from "react-chartjs-2";

//TEMP URL
//var url = "https://api.climatece.co.uk/"
var url = "https://b0a61x4pc5.execute-api.eu-west-2.amazonaws.com/Prod/service-suggestions/"

let payload = {

    "access_key": "demo",

    "secret_key": "demo",
    
    "start_date": "2021-10-11",
    
    "end_date": "2022-01-10"
    
    }
let suggestions = []
var response = ''
var forecastData;
let sumS3, sumRDS, sumEC2, sumDDB, sumLambda, sumKinesis, sumTotal, forecastTotal;
let monthTotal, percChange, percColour, percInc, lastMonthT, thisMonthT, monthChange, monthColour, monthInc;
let reportSuggestions = [];
let unitS3, unitRDS, unitEC2, unitDDB, unitLambda, unitKinesis ;
let serviceOrder = [];
var total, chartData, monthlyReviewData;
var date = new Date();
date.setDate(date.getDate() - 355);
var start_date = date.toISOString().substring(0,10);

var date2 = new Date();
date2.setDate(date2.getDate());
var end_date = date2.toISOString().substring(0,10);

var date3 = new Date();
date3.setMonth(date3.getMonth()-1);
var thisMonthDate = date3.toISOString().substring(0,10);

var date4 = new Date();
date4.setMonth(date4.getMonth()-2);
var lastMonthDate = date4.toISOString().substring(0,10);


const graphOptionsBar = {
    indexAxis: 'y',
	scales: {
			  y: {
				  title: {
					  display:true,
					  text:"Services",
					  font: {
						  size: 8
					  }
				  }
			  },
			  x: {
				  title: {
					  display:true,
					  text:"% Contribution to Emissions",
					  font: {
						  size: 8
					  }
				  }
			  }        
		}
        
};

const graphOptions = {
	scales: {
			  y: {
				  title: {
					  display:true,
					  text:"Emissions (grams CO2)",
					  font: {
						  size: 8
					  }
				  }
			  },
			  x: {
				  title: {
					  display:true,
					  text:"Date",
					  font: {
						  size: 8
					  }
				  }
			  }        
		}
};

const graphOptions60Day = {
	scales: {
			  y: {
				  title: {
					  display:true,
					  text:"Emissions (unit / CO2)",
					  font: {
						  size: 8
					  }
				  }
			  },
			  x: {
				  title: {
					  display:true,
					  text:"Day",
					  font: {
						  size: 8
					  }
				  }
			  }        
		}
};

    function removeBelowZero(x){
        if (x < 0) {
            return 0;
        } else{
            return x;
        }
    }

    function zip(arr1,arr2,out={}){
        arr1.map( (val,idx)=>{ out[val] = arr2[idx]; } );
        return out;
    }

class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.fetchData = this.fetchData.bind(this);
        this.fetchData();
        this.totalEmissions();
        this.getForecast();
    }

    //FETCHING DATA FROM API AND MULTIPLE ENDPOINTS
    async fetchData() {
        //parameters = url + service + token;
        const apiRequest = {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }; 

        await Promise.all([
            fetch('https://b0a61x4pc5.execute-api.eu-west-2.amazonaws.com/Prod/service-suggestions', apiRequest)
        ]).then(function (responses){
            return Promise.all(responses.map(function (response) {
                console.log(response)

                return response.json();
            }));
        }).then( data => response = data
        ).catch(function (error) {
            // if there's an error, log it
            console.log('Fetch error:' +error);
        });           
        console.log(response);
        this.totalEmissions();
    }

    totalEmissions() {
        var unit, annualEmissionDis, annualEmission = 0;
        let metric = [this.props.state.EC2['emissions'],this.props.state.S3['emissions'],this.props.state.DDB['emissions'],this.props.state.RDS['emissions'],this.props.state.Lambda['emissions'],this.props.state.Kinesis['emissions']]
            //LOOP PER NUMBER OF SERVICES
            for (let i=0;i<metric.length;i++) {
                //LOOP PER ENTRY OF DATA WITHIN THE SERVICE
                for (let j=0;j<metric[i].length;j++) {
                    annualEmission += metric[i][j]
                }
            }
            if(annualEmission>1000 && annualEmission < 1000000) {
                annualEmissionDis = (annualEmission/1000);
                unit = 'kg'
            } else if(annualEmission>1000000) {
                annualEmissionDis = (annualEmission/1000000);
                unit = 'tonne'
            } else {
                annualEmissionDis = annualEmission;
            }
        total = annualEmissionDis.toFixed(2)+' '+unit
        this.monthlyReview()
    }

    monthlyReview() {
        //Last months service data
        let lastec2Month = (this.props.state.EC2['emissions'].slice(this.props.state.EC2['dates'].indexOf(lastMonthDate), this.props.state.EC2['dates'].indexOf(thisMonthDate)));
        let lasts3Month = (this.props.state.S3['emissions'].slice(this.props.state.S3['dates'].indexOf(lastMonthDate), this.props.state.S3['dates'].indexOf(thisMonthDate)));
        let lastddbMonth = (this.props.state.DDB['emissions'].slice(this.props.state.DDB['dates'].indexOf(lastMonthDate), this.props.state.DDB['dates'].indexOf(thisMonthDate)));
        let lastrdsMonth = (this.props.state.RDS['emissions'].slice(this.props.state.RDS['dates'].indexOf(lastMonthDate), this.props.state.RDS['dates'].indexOf(thisMonthDate)));
        let lastlambdaMonth = (this.props.state.Lambda['emissions'].slice(this.props.state.Lambda['dates'].indexOf(lastMonthDate), this.props.state.Lambda['dates'].indexOf(thisMonthDate)));
        let lastkinesisMonth = (this.props.state.Kinesis['emissions'].slice(this.props.state.Kinesis['dates'].indexOf(lastMonthDate), this.props.state.Kinesis['dates'].indexOf(thisMonthDate)));

        let last30Total = []
        last30Total.push(lastec2Month,lasts3Month,lastddbMonth,lastrdsMonth,lastlambdaMonth,lastkinesisMonth);
        //This months service data
        let ec2Month = (this.props.state.EC2['emissions'].slice(this.props.state.EC2['dates'].indexOf(thisMonthDate), this.props.state.EC2['dates'].indexOf(end_date)));
        let s3Month = (this.props.state.S3['emissions'].slice(this.props.state.S3['dates'].indexOf(thisMonthDate), this.props.state.S3['dates'].indexOf(end_date)));
        let ddbMonth = (this.props.state.DDB['emissions'].slice(this.props.state.DDB['dates'].indexOf(thisMonthDate), this.props.state.DDB['dates'].indexOf(end_date)));
        let rdsMonth = (this.props.state.RDS['emissions'].slice(this.props.state.RDS['dates'].indexOf(thisMonthDate), this.props.state.RDS['dates'].indexOf(end_date)));
        let lambdaMonth = (this.props.state.Lambda['emissions'].slice(this.props.state.Lambda['dates'].indexOf(thisMonthDate), this.props.state.Lambda['dates'].indexOf(end_date)));
        let kinesisMonth = (this.props.state.Kinesis['emissions'].slice(this.props.state.Kinesis['dates'].indexOf(thisMonthDate), this.props.state.Kinesis['dates'].indexOf(end_date)));

        let this30Total = []
        this30Total.push(ec2Month,s3Month,ddbMonth,rdsMonth,lambdaMonth,kinesisMonth);

        let lastMonthTotal=0;
        let thisMonthTotal=0;
        for (let i=0;i<this30Total.length;i++) {
            for (let j=0;j<this30Total[i].length;j++) {                
                thisMonthTotal += this30Total[i][j];    
            }
            for (let j=0;j<last30Total[i].length;j++) {
                lastMonthTotal += last30Total[i][j];
            }
        }
        lastMonthT = this.unitCheck(lastMonthTotal);
        thisMonthT = this.unitCheck(thisMonthTotal);

        if (thisMonthTotal<lastMonthTotal) {
            monthChange = -(100-((thisMonthTotal/lastMonthTotal)*100)).toFixed(4)
            monthColour = 'green'
            monthInc = 'decrease'
        } else {
            monthChange = ((thisMonthTotal/lastMonthTotal)*100-100).toFixed(4)
            monthColour = 'red'
            monthInc = 'increase'
        }

        //CHANGING THIS GRAPH - JUST MAKING A START ON IT
        monthlyReviewData = {
           // labels: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
            scales: {
                _xScale: [{
                    min: 0,
                    max: 2000
                }]
            },
            datasets: [{ 
                label: (date3.getDate() +'/'+ date3.getMonth()+'/'+ (date3.getFullYear()) + ' - ' + date3.getDate() +'/'+ (date3.getMonth()+1)+'/'+ (date3.getFullYear())),
                data: zip(this.props.state.EC2['dates'].slice(this.props.state.EC2['dates'].indexOf(lastMonthDate), this.props.state.EC2['dates'].indexOf(thisMonthDate)),this30Total[1]),
                labelColor: '#6219c0',
                borderColor: '#6219c0',
                backgroundColor: '#6219c0',
                pointBorderColor: "transparent",
                pointBackgroundColor: "transparent",
                tension: 0.2
            },
            { 
                label: (date3.getDate() +'/'+ (date3.getMonth()+1)+'/'+ (date3.getFullYear()) + ' - ' + date2.getDate() +'/'+ (date2.getMonth()+1)+'/'+ (date2.getFullYear())),
                data: zip(this.props.state.EC2['dates'].slice(this.props.state.EC2['dates'].indexOf(thisMonthDate), this.props.state.EC2['dates'].indexOf(end_date)),last30Total[1]),
                labelColor: '#40c5ca',
                borderColor: '#40c5ca',
                backgroundColor: '#40c5ca',
                pointBorderColor: "transparent",
                pointBackgroundColor: "transparent",
                tension: 0.2
            }]
        };
        this.serviceTotals()
    }

    serviceTotals() {
		sumS3 = sumRDS = sumEC2= sumDDB = sumLambda = sumKinesis = sumTotal = 0;

        //POPULATING VARIABLES TO REDUCE CODE CLUTTERING
        //BELOW THE EMISSION DATA CORRESPONDING TO THE RESPECTIVE METRICS IS POPULATED
        let EC2Emissions = this.props.state.EC2["emissions"];
        let S3Emissions = this.props.state.S3["emissions"];
        let DDBEmissions = this.props.state.DDB["emissions"];
        let RDSEmissions = this.props.state.RDS["emissions"];
        let LambdaEmissions = this.props.state.Lambda["emissions"];
        let KinesisEmissions = this.props.state.Kinesis["emissions"];
        
        //SUM OF THE ANNUAL EMISSION PER SERVICE IS CALCULATED BELOW
        for (let i=0;i<EC2Emissions.length;i++) {
            sumEC2 += EC2Emissions[i];
            sumS3 += S3Emissions[i];
            sumDDB += DDBEmissions[i];
            sumRDS += RDSEmissions[i];
            sumLambda += LambdaEmissions[i];
            sumKinesis += KinesisEmissions[i];
            
			sumTotal += EC2Emissions[i] + S3Emissions[i] + DDBEmissions[i] + RDSEmissions[i] + LambdaEmissions[i] + KinesisEmissions[i]
        };

        //initialise unit sorting - tonnes, kg, g
        //THE ORDER OF EMISSIONS IS THEN SORTED
        serviceOrder = [];
        unitEC2 = this.unitCheck(sumEC2);
        unitS3 = this.unitCheck(sumS3);
        unitDDB = this.unitCheck(sumDDB);
        unitRDS = this.unitCheck(sumRDS);
        unitLambda = this.unitCheck(sumLambda);
        unitKinesis = this.unitCheck(sumKinesis);

        serviceOrder.push({'service': 'EC2', 'totalGrams': sumEC2.toFixed(2), 'perc': ((sumEC2/sumTotal)*100).toFixed(2), 'totalConverted': unitEC2[0]['num'], 'units': unitEC2[0]['u']},{'service': 'S3', 'totalGrams': sumS3.toFixed(2),  'perc': ((sumS3/sumTotal)*100).toFixed(2), 'totalConverted': unitS3[0]['num'], 'units': unitS3[0]['u']},{'service': 'DynamoDB', 'totalGrams': sumDDB.toFixed(2),  'perc': ((sumDDB/sumTotal)*100).toFixed(2), 'totalConverted': unitDDB[0]['num'], 'units': unitDDB[0]['u']},{'service': 'RDS',  'totalGrams': sumRDS.toFixed(2), 'perc': ((sumRDS/sumTotal)*100).toFixed(2), 'totalConverted': unitRDS[0]['num'], 'units': unitRDS[0]['u']},{'service': 'Lambda', 'totalGrams': sumLambda.toFixed(2),  'perc': ((sumLambda/sumTotal)*100).toFixed(2), 'totalConverted': unitLambda[0]['num'], 'units': unitLambda[0]['u']},{'service': 'Kinesis', 'totalGrams': sumKinesis.toFixed(2),  'perc': ((sumKinesis/sumTotal)*100).toFixed(2), 'totalConverted': unitKinesis[0]['num'], 'units': unitKinesis[0]['u']})
        //using sort() to order the elements descending by using reverse() - a,b are used to specify which keys to compare
        serviceOrder = serviceOrder.sort((a,b)=>a.perc -b.perc).reverse();

        chartData = {
            labels: [serviceOrder[0]['service'],serviceOrder[1]['service'],serviceOrder[2]['service'],serviceOrder[3]['service'],serviceOrder[4]['service'],serviceOrder[5]['service'],'Sagemaker','Connect','Quicksight','serv10'],
            scales: {
                _xScale: [{
                    min: 0,
                    max: 2000
                }]
            },
            datasets: [{
                label: 'Services',
                data: [serviceOrder[0]['perc'],serviceOrder[1]['perc'],serviceOrder[2]['perc'],serviceOrder[3]['perc'],serviceOrder[4]['perc'],serviceOrder[5]['perc'],serviceOrder[2]['perc'],serviceOrder[3]['perc'],serviceOrder[4]['perc'],serviceOrder[5]['perc']],
                labelColor: '#000000',
                backgroundColor: ["#6219c0", "#40c5ca","#e4873b","#dfd111", "#7c0436","#ff05b4"],
                borderColor:'#FFFFFF',
                pointBorderColor: "#111111",
                pointBackgroundColor: "#FFFFFF",
            }
            ]
        };

        //gather report suggestions based on top two services
        reportSuggestions.push('Move to lambda (triggered by events and not constantly running like ec2)', '         Delete unattached EBS volumes – (when EC2 instance is launched an EBS is attached as a local block storage unless you configure AWS console to delete the volume when instance is terminated)', 'Using spot instances instead of on demand, spot instances can allow access to spare EC2 computing capacity', 'delete incomplete multipart uploads – S3 retains all the uploaded parts until its complete or aborted, if not aborted or completed successfully it will still be stored and you’ll be charged – create lifecycle policy to clean up incomplete loads after several days','delete old versions – be careful as S3 can’t restore data deleted from buckets and if you have object versioning then each version adds to costs')
    }

    unitCheck(serviceTotal) {
        let serviceTotalUnit, unit;
        if(serviceTotal>1000 && serviceTotal < 1000000) {
            serviceTotalUnit = (serviceTotal/1000);
            unit = 'kg'
        } else if(serviceTotal>1000000) {
            serviceTotalUnit = (serviceTotal/1000000);
            unit = 'tonne'
        } else {
            serviceTotalUnit = serviceTotal;
            unit = 'g'
        }
        serviceTotalUnit = serviceTotalUnit.toFixed(2);
        let response = []
        response = [{'num': serviceTotalUnit,'u': unit}]
        return response;
    }

    //DEAL WITH FORECASTING DISPLAY FOR MOST SIGNIFICANT SERVICE
    getForecast() {
        var highestService, forecastEmissions;

        //having to temporarily use these if blocks to deal with the below - will revisit
        if (serviceOrder[0]['service'] === 'EC2') {
            highestService = this.props.state.EC2
            forecastEmissions = this.props.state.Forecast.forecast.ec2.p50.map(removeBelowZero);
        } else if (serviceOrder[0]['service'] === 'S3') {
            highestService = this.props.state.S3
            forecastEmissions = this.props.state.Forecast.forecast.s3.p50.map(removeBelowZero);
        }else if (serviceOrder[0]['service'] === 'DDB') {
            highestService = this.props.state.DDB
            forecastEmissions = this.props.state.Forecast.forecast.ddb.p50.map(removeBelowZero);
        }else if (serviceOrder[0]['service'] === 'RDS') {
            highestService = this.props.state.RDS
            forecastEmissions = this.props.state.Forecast.forecast.rds.p50.map(removeBelowZero);
        }else if (serviceOrder[0]['service'] === 'Lambda') {
            highestService = this.props.state.Lambda
            forecastEmissions = this.props.state.Forecast.forecast.lambda.p50.map(removeBelowZero);
        }else if (serviceOrder[0]['service'] === 'Kinesis') {
            highestService = this.props.state.Kinesis
            forecastEmissions = this.props.state.Forecast.forecast.kinesis.p50.map(removeBelowZero);
        }
        
        forecastEmissions = forecastEmissions.slice(0, forecastEmissions.length);
        var forecastDates = this.props.state.Forecast.forecast.dates.slice(0, forecastEmissions.length/2);
        var pastDates = highestService.dates.slice(Math.max(highestService.dates.length - forecastEmissions.length/2, 0));
        var pastEmissions = highestService.emissions.slice(Math.max(highestService.dates.length - forecastEmissions.length/2, 0));

    
        forecastData = {
            scales: {
                _xScale: [{
                    min: 0,
                    max: 2000
                }]
            },
            datasets: [{ 
                label: serviceOrder[0]['service'],
                data: zip(pastDates,pastEmissions),
                labelColor: '#6219c0',
                borderColor: '#6219c0',
                backgroundColor: '#6219c0',
                pointBorderColor: "transparent",
                pointBackgroundColor: "transparent",
                tension: 0.2
            },{ 
                label: serviceOrder[0]['service']+" Forecast",
                data: zip(forecastDates,forecastEmissions),
                labelColor: '#6219c0',
                borderColor: '#6219c0',
                backgroundColor: '#6219c0',
                pointBorderColor: "transparent",
                pointBackgroundColor: "transparent",
                tension: 0.2,
                borderDash: [5,5]
            }]
        };

        //TOTAL FORECAST EMISSIONS
        let tempMonthTotal = 0;
        let tempForecastTotal = 0;
        //using last 31 days of emission data from the highest used service data
        var monthlyEmissions = highestService["emissions"].slice(325);

        for (let i=0;i<monthlyEmissions.length;i++) {
            tempMonthTotal += monthlyEmissions[i]
			tempForecastTotal += forecastEmissions[i]
        };
        monthTotal = this.unitCheck(tempMonthTotal);
        forecastTotal = this.unitCheck(tempForecastTotal);
        
        if (tempForecastTotal<tempMonthTotal) {
            percChange = (100-((tempForecastTotal/tempMonthTotal)*100).toFixed(4))
            percColour = 'green'
            percInc = 'decrease'
        } else {
            percChange = ((tempForecastTotal/tempMonthTotal)*100-100).toFixed(4)
            percColour = 'red'
            percInc = 'increase'
        }

    }


    generatePDF() {
        //INITIALISING PDF HERE
        const pdf = new jsPDF();
        const input = document.getElementById('divToPrint');
        htmlToImage.toPng(input)
    //ADDING FIRST PAGE 'IMAGE' TO PDF
      .then(function (dataUrl) {
        pdf.addImage(dataUrl, 'JPEG', 20, 0);
        //Adding in rest of pages to pdf
        htmlToImage.toPng(document.getElementById('divToPrint2'))
        .then(function (dataUrl1) {
            pdf.addPage();
            pdf.addImage(dataUrl1, 'JPEG', 20, 0);
            htmlToImage.toPng(document.getElementById('divToPrint3'))
            .then(function (dataUrl1) {
                pdf.addPage();
                pdf.addImage(dataUrl1, 'JPEG', 20, 0);
                htmlToImage.toPng(document.getElementById('divToPrint4'))
                .then(function (dataUrl3) {
                    pdf.addPage();
                    pdf.addImage(dataUrl3, 'JPEG', 20, 0);  
                    //IN FINAL 'then' WE SAVE THE PDF
                    pdf.save("ClimateCEreport.pdf");
                })
            })
        })
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
    }

    render() {
        return (
            <main class="dashboard-container">
                <div class="dashboard-main-title">
                    <h1 class="fw-light text-center"><b>Report Summary.</b></h1>
                </div>
                    <h5 class='dashboard-metric-title' style={{textAlign:'center'}}>Overall Emission Data</h5>
                    <div class="overall-container"> 
                        <div class="dashboard-metrics">
                            <div class="card">
                                <div class='card-body' >
                                <div class="dashboard-metric-items">
                                <div class="card" id='summaryCard'>
                                    <div id='divToPrint' className="mt4">
                                        <h6 style={{textDecoration: 'underline'}}>ClimateCE Emission Report: {end_date}</h6> 
                                        <p>Account E-mail: {this.props.state.email}<br/>As of <b>{start_date}</b>, total emissions: <b>{total}</b></p>
                                        <p><b>60 Day Review of Emissions:</b></p>
                                        <p>Since {lastMonthDate} to {thisMonthDate}: <b>{lastMonthT[0]['num']} {lastMonthT[0]['u']}</b></p>
                                        <p>Since {thisMonthDate} to {end_date}: <b>{thisMonthT[0]['num']} {thisMonthT[0]['u']}</b></p>
                                        <p>Emission Comparison: <b style={{color: monthColour}}>{monthChange}% {monthInc}</b></p>
                                        <Line data={monthlyReviewData} options={graphOptions60Day}  width={"100%"} height={"80%"} class='dashboard-chart'/>

                                    </div>
                                    <hr/>
                                    <div id='divToPrint2' className="mt4">
                                    <p style={{textDecoration: 'underline'}}><b>Annual Emissions Review:</b></p>
                                        <p style={{textDecoration: 'underline'}}><b>Five Highest Emission Services:</b></p>
                                        <table class='reportHeader'>
                                            <thead class='reportHeader'>
                                                <tr>
                                                <th scope="col" >Service</th>
                                                <th scope="col" style={{textAlign: "right"}}>Emission (unit/CO<sub>2</sub>)</th>
                                                <th scope='col'>% Contribution</th>
                                                </tr>
                                            </thead>
                                            <tbody class='reportBody'>
                                                <tr>
                                                <td>{serviceOrder[0]['service']}</td>
                                                <td style={{textAlign: "right"}}>{serviceOrder[0]['totalConverted']} {serviceOrder[0]['units']}</td>
                                                <td>{serviceOrder[0]['perc']}</td>
                                                </tr>
                                                <tr>
                                                <td>{serviceOrder[1]['service']}</td>
                                                <td style={{textAlign: "right"}}>{serviceOrder[1]['totalConverted']} {serviceOrder[1]['units']}</td>
                                                <td>{serviceOrder[1]['perc']}</td>
                                                </tr>
                                                <tr>
                                                <td>{serviceOrder[2]['service']}</td>
                                                <td style={{textAlign: "right"}}>{serviceOrder[2]['totalConverted']} {serviceOrder[2]['units']}</td>
                                                <td>{serviceOrder[2]['perc']}</td>
                                                </tr>
                                                <tr>
                                                <td>{serviceOrder[3]['service']}</td>
                                                <td style={{textAlign: "right"}}>{serviceOrder[3]['totalConverted']} {serviceOrder[3]['units']}</td>
                                                <td>{serviceOrder[3]['perc']}</td>
                                                </tr>
                                                <tr>
                                                <td>{serviceOrder[4]['service']}</td>
                                                <td style={{textAlign: "right"}}>{serviceOrder[4]['totalConverted']} {serviceOrder[4]['units']}</td>
                                                <td>{serviceOrder[4]['perc']}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        
                                        <p style={{textDecoration: 'underline'}}><b>10 Service Comparison:</b></p>
                                        <article>
                                            <Bar data={chartData} options={graphOptionsBar} width={"100%"} height={"80%"} class='dashboard-chart'/>
                                        </article>
                                    </div>
                                    <hr/>
                                    <div id='divToPrint3' className="mt4">
                                        <p style={{textDecoration: 'underline'}}><b>Highest Service Emission Forecast:</b></p>
                                        <p>Forecast for the next <b>31</b> days from <b>{end_date}</b></p>
                                        <article>
                                            <Line data={forecastData} options={graphOptions}  width={"100%"} height={"80%"} class='dashboard-chart'/>
                                        </article>
                                        <p>Previous Month Total Emissions: <b>{monthTotal[0]['num']} {monthTotal[0]['u']}</b></p>
                                        <p>Forecast Month Total Emissions: <b>{forecastTotal[0]['num']} {forecastTotal[0]['u']}</b></p>
                                        <p>Projected Emission Comparison: <b style={{color: percColour}}>{percChange}% {percInc}</b></p>
                                    </div>
                                    <hr/>
                                    <div id='divToPrint4' className="mt4" >
                                    <p style={{textDecoration: 'underline'}}><b>Suggestions:</b></p>
                                        <p>Below can be found five points of suggested AWS usage, focusing primarily on your two most used services:</p> 
                                        <p style={{textDecoration: 'underline'}}>{serviceOrder[0]['service']}</p>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>{reportSuggestions[0]}</ListGroup.Item>
                                            <ListGroup.Item>{reportSuggestions[1]}</ListGroup.Item>
                                            <ListGroup.Item>{reportSuggestions[2]}</ListGroup.Item>
                                            
                                        </ListGroup>
                                        <p style={{textDecoration: 'underline'}}>{serviceOrder[1]['service']}</p>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>{reportSuggestions[3]}</ListGroup.Item>
                                            <ListGroup.Item>{reportSuggestions[4]}</ListGroup.Item>
                                        </ListGroup>
                                        <p>These suggestions, are based on standard practice and common wastage scenarios of these services.</p>
                                        <br/>
                                    </div>
                                </div>
                                </div>
                                <h5 class="dashboard-metric-title" style={{textAlign:'center'}}>Export Report</h5><br/> 
                                <div class="overall-container"> 
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-outline-success" onClick={event => {this.generatePDF(event)}}>Print as PDF</button>
                                        <button type="button" class="btn btn-outline-success" onClick={event => {this.generatePDF(event)}}>Send Email Copy</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>        
        )
    } 
}
export default Summary;
