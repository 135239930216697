import React from 'react';
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";

const graphDataS3 = {
		labels: ['20/10/21', '21/10/21', '22/10/21', '23/10/21', '24/10/21'],
		scales: {
			_yScale: [{
				min: 0,
				max: 2000
			}]},
		datasets: [
		  {
			label: 'S3 Emissions',
			data: [6560, 4050, 3000, 2500, 5560],
			backgroundColor: "#40ceaa",
		  },
		], 
};

const graphDataRDS = {
		labels: ['20/10/21', '21/10/21', '22/10/21', '23/10/21', '24/10/21'],
		scales: {
			_yScale: [{
				min: 0,
				max: 2000
			}]},
		datasets: [
		  {
			label: 'RDS Emissions',
			data: [5100, 7250, 6150, 8235, 5980],
			labelColor: '#40c5ce',
			borderColor: "#40c5ce",
			backgroundColor: "#40c5ce",
			pointBorderColor: "#111111",
			pointBackgroundColor: "#FFFFFF",
			tension: 0.4
		  },
		], 
};

const graphOptions = {
	scales: {
			  y: {
				  title: {
					  display:true,
					  text:"Emissions",
					  font: {
						  size: 14
					  }
				  }
			  },
			  x: {
				  title: {
					  display:true,
					  text:"Date",
					  font: {
						  size: 14
					  }
				  }
			  }
		}
};

class PublicHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disView: 'publicHome'
        }; 
    }

    render () {
        if (this.state.disView === 'publicHome') { 
            return (
                <div className={"publicHome"}>
										<br/> <br/>
                    <div class=" align-items-center bg-hero">    
                        <div class="px-3 py-2 my-3 text-center off-black-text">
														<img src="https://cliply.co/wp-content/uploads/2021/02/392102850_EARTH_EMOJI_400px.gif" style={{width:"150px",height:"auto",marginLeft:"auto",marginRight:"auto",display:"block"}} alt=""/>
                            <p class="display-1 fw-bold f1">Climate Cost Explorer </p> 
                            <p><h4 class="fw-light">Using AWS Cost Explorer API</h4></p>
                            <div class="col-lg-6 mx-auto">
                                <p class="lead mb-4 ">Climate Cost Explorer is a tool that can be used to track your company’s emissions created by AWS infrastructure usage, based on data collected by AWS Cost Explorer API. </p> <p class="lead mb-4 fw-light"> Sign in to see the emissions created by your AWS services!</p>
                            </div>
                        </div>
                    </div>
				    <div class="card-container">	
							<div class="server-stack">
								<img class="server-layer" src={require("../static/server-top.png").default} />
								<img class="server-layer" src={require("../static/server-mid.png").default}/>
								<img class="server-layer" src={require("../static/server-bottom.png").default}  />
								<img class="server-layer" src={require("../static/server-widget-shadow.png").default}/>
							</div>
							<div class="thought public-home-card shadow-sm"> 
								<p class="thought-text lead">Each AWS service produces a certain amount of carbon dioxide due to the electricity used to run the server that it is on.</p>
							</div>
							<div class="thought public-home-card shadow-sm"> 
								<p class="lead thought-text">Every single service on AWS has an underlying server that is running therefore all services contribute to the emissions however some are more harmful than others.</p>
							</div>
							<div class="card" style={{padding:"0.5rem",width:"25rem",height:"auto"}}>
								<img src="https://i.imgur.com/KDCxm2v.png" style={{width:"100%",height:"100%",borderRadius:"3px"}} alt=""/>
							</div>
							<div class="card" style={{padding:"0.5rem",width:"25rem",height:"auto"}}>
								<img src="https://i.imgur.com/Bnpqcvh.png" style={{width:"100%",height:"100%",height:"auto",borderRadius:"3px"}} alt=""/>
							</div>
							<div class="thought public-home-card shadow-sm"> 
								<p class="lead thought-text">Using this application can give you the opportunity to find the most harmful services running on a given infrastructure and optimise them!</p>
							</div>
							<h3>Grad Project Hosted by Natan Szczepaniak</h3>
                    </div>
                </div> 
            )
        } 
    }
}

export default PublicHome;
