import React from 'react';
import './CSS/loading.css';

var date = new Date();
date.setDate(date.getDate()-355);
var start_date = date.toISOString().substring(0,10);

var date2 = new Date();
date2.setDate(date2.getDate() );
var end_date = date2.toISOString().substring(0,10);

let carbonCount = 0;
let carbonMetric = '';
let totalImage = "../static/cat.png";
let annualEmission,annualEmissionDis =0;
let unit = 'g';

class Comparator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disView: 'comparator'
        };
        this.totalEmissions();
    }

    //CALCULATES A TOTAL EMISSION VALUE FOR ALL 6 SERVICES ACROSS THE ENTIRE FETCHED DATA RANGE
    totalEmissions() {
        annualEmission = 0;
        let metric = [this.props.state.EC2['emissions'],this.props.state.S3['emissions'],this.props.state.DDB['emissions'],this.props.state.RDS['emissions'],this.props.state.Lambda['emissions'],this.props.state.Kinesis['emissions']]
            //LOOP PER NUMBER OF SERVICES
            for (let i=0;i<metric.length;i++) {
                //LOOP PER ENTRY OF DATA WITHIN THE SERVICE
                for (let j=0;j<metric[i].length;j++) {
                    annualEmission += metric[i][j]
                }
            }
            if(annualEmission>1000 && annualEmission < 1000000) {
                annualEmissionDis = (annualEmission/1000);
                unit = 'kg'
            } else if(annualEmission>1000000) {
                annualEmissionDis = (annualEmission/1000000);
                unit = 'tonne'
            } else {
                annualEmissionDis = annualEmission;
            }
        this.Comparison();
    }
    
    //WORK OUT WHICH COMPARISON THIS WOULD COME UNDER AND UPDATE IMAGE ON DISPLAY
    Comparison() {   
        carbonCount = 0;    
        carbonMetric = this.props.state.Comparator['metric'];
        var temp = this.props.state.Comparator['value'];
        carbonCount = temp.slice(0, temp.indexOf('.')+3)
        if (carbonMetric === 'cats') {
            totalImage = "http://pngimg.com/uploads/cat/cat_PNG50432.png";//require("../static/cat.png").default;
        } else if (carbonMetric === 'watermelon') {
            totalImage = "http://pngimg.com/uploads/watermelon/watermelon_PNG234.png";//require("../static/watermelon.png").default;
        } else if (carbonMetric === 'grand') {
            carbonMetric = 'grand piano';
            totalImage = "https://www.freepnglogos.com/uploads/piano-png/piano-photo-francomac-photobucket-1.png";//require("../static/piano.png").default;
        } else if (carbonMetric === 'baby') {
            carbonMetric = 'baby elephant';
            totalImage = "https://i.imgur.com/0kpCHKG.png";//require("../static/elephant.png").default;
        } else if (carbonMetric === 'car') {
            totalImage = "https://freepngimg.com/download/volkswagen/31-red-old-volkswagen-beetle-png-car-image.png";//require("../static/car.png").default;
        } else if (carbonMetric === 'firetruck') {
            totalImage = "https://www.pngall.com/wp-content/uploads/4/Fire-Truck-PNG-Free-Download.png";//require("../static/truck.png").default;
       } else if (carbonMetric === 'spaceshuttle') { 
            carbonMetric = 'space shuttle';
            totalImage = "https://i.imgur.com/C1GAenW.png";//require("../static/whale.png").default;
        } else if (carbonMetric === 'eiffel') {
            carbonMetric = 'eiffel tower';
            totalImage = "https://pngimg.com/uploads/eiffel_tower/eiffel_tower_PNG38.png";//require("../static/tower.png").default;
        }
    }

       
    render() {
        if (this.state.disView === 'comparator') {
            return (
                <main>
                    <div class="dashboard-main-title">
                            <h1 class="fw-light text-center">Weight equivalents of carbon emissions</h1>
                        </div>
                        
                            <div class="card">
                            <div class="overall-container">
                                    <div class="card shadow-sm compare-container">
                                        <div class="card-body" style={{textAlign: 'center'}}> 
                                            <h4 style={{textAlign: 'center'}}>Annual account emissions:</h4><hr/>
                                                <h5 style={{textAlign: 'center'}}><b>Total: {annualEmissionDis.toFixed(2)} {unit}</b></h5><hr/>
                                                <p>Data available between:<br/><b>{start_date}</b> and <b>{end_date}</b></p>
                                                <h5>Annual emission comparison:</h5>
                                                <p><b>{carbonCount} {carbonMetric} !</b></p>
                                                <img style={{width: '18rem'}} src={totalImage} alt='/#'/>
                                        </div>   
                                    </div>
                                    <div class="card shadow-sm compare-container">
                                        <div class="card-body" style={{alignItems: 'center'}}> 
                                            <h4>Key of metrics:</h4>
                                            <p>Based on their objective weight</p>
                                            
                                                <table class="table" id="comparatorTable">
                                                <thead class="thead-dark">
                                                    <tr>
                                                    <th scope="col" class="td_tags">#</th>
                                                    <th scope="col" class="td_tags">Metric</th>
                                                    <th scope="col" class="td_tags">Mass (kg)</th>
                                                    </tr>
                                                </thead>
                                                <tbody alignItems ="center">
                                                    <tr>
                                                    <td> <img src={"http://pngimg.com/uploads/cat/cat_PNG50432.png"} alt='/#' class="comparator_containers" /></td>
                                                    <td class ="td_tags">Cats</td>
                                                    <td class = "td_tags">5</td>
                                                    </tr>
                                                    <tr>
                                                    <td> <img src={"http://pngimg.com/uploads/watermelon/watermelon_PNG234.png"} alt='/#' class="comparator_containers" /></td>
                                                    <td class = "td_tags">Watermelon</td>
                                                    <td class = "td_tags">9</td>
                                                    </tr>
                                                    <tr>
                                                    <td> <img src={"https://i.imgur.com/0kpCHKG.png"} alt='/#' class="comparator_containers" /></td>
                                                    <td class = "td_tags">Baby Elephant</td>
                                                    <td class = "td_tags">90</td>
                                                    </tr>
                                                    <tr>
                                                    <td> <img src={"https://www.freepnglogos.com/uploads/piano-png/piano-photo-francomac-photobucket-1.png"} alt='/#' class="comparator_containers" /></td>
                                                    <td class = "td_tags">Grand Piano</td>
                                                    <td class = "td_tags"> 450</td>
                                                    </tr>
                                                    <tr>
                                                    <td> <img src={"https://freepngimg.com/download/volkswagen/31-red-old-volkswagen-beetle-png-car-image.png"} alt='/#' class="comparator_containers"/></td>
                                                    <td class = "td_tags">Volkswagen</td>
                                                    <td class = "td_tags">952</td>
                                                    </tr>
                                                    <tr>
                                                    <td> <img src={"https://www.pngall.com/wp-content/uploads/4/Fire-Truck-PNG-Free-Download.png"} alt='/#' class="comparator_containers" /></td>
                                                    <td class = "td_tags">Firetruck</td>
                                                    <td class = "td_tags">25000</td>
                                                    </tr>
                                                    <tr>
                                                    <td> <img src={"https://i.imgur.com/C1GAenW.png"} alt='/#' class="comparator_containers" /></td>
                                                    <td class = "td_tags">Space Shuttle</td>
                                                    <td class = "td_tags">85000</td>
                                                    </tr>
                                                    <tr>
                                                    <td > <img src={"https://pngimg.com/uploads/eiffel_tower/eiffel_tower_PNG38.png"} alt='/#' class="comparator_containers" /></td>
                                                    <td class = "td_tags">Eiffel Tower</td>
                                                    <td class = "td_tags">1000000</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </main>
            )
        } else if (this.state.disView === 'load') {
            return (
                <div class={"Loading"}>
                    <p>Loading</p>
                    <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div> 
                </div>
            )
        }
    } 
}
export default Comparator;
