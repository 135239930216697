import React, { useState } from "react";
import UserPool from "../UserPool";
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

/**
 * SignInForm to create, load and handle the user sign in information and page
 */
const SignInForm = ({update,state,checkUser}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

 //To be executed when the user presses the submit button
  const onSubmit = (event) => {
    event.preventDefault();

    //Create a new cognito user to handle authentication based on account email within a user pool
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    //Store user's authentication details
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    //Authenticate user 
    user.authenticateUser(authDetails, {
      //If successful store the user's data in local storage and run the checkUser() method to handle the rest of the operations within the app
      onSuccess: (data) => {
        console.log("onSuccess: ", data); 
		localStorage.setItem("userData",JSON.stringify(data));
		checkUser();
      },
      //If unsuccessful, display an error message to notify the user else ask for a new password
      onFailure: (err) => {
		if (err.message == "User is not confirmed.") {
			localStorage.setItem("verifyEmail",email);
			update("verifyUser",false);
		}
		else {
			setErrorMessage(err.message);
		}
      },
      newPasswordRequired: (data) => {
        console.log("newPasswordRequired: ", data);
      },
    });
  };
  //When the user hasn't registered, prompt them to register page
  const onRegister = (event) => {
    event.preventDefault();
	update("signUp",false);
  };

  //Show password entry feature
  const showPwd = (event) => {
    event.preventDefault();
    var x = document.getElementById("passwordEntry");
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
  }

  return (
    <div class="sign-up-container">
	  <h1>Sign In</h1>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" value={email} onChange={(event) => setEmail(event.target.value)} />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control id='passwordEntry' type="password" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)}/>
        </Form.Group>
        <div class="text-center"><p><input id='passwordShow' type="checkbox" onClick={(event) => showPwd(event)}/> Show Password</p></div>
        <div class="text-center"> 
        <Button class="primary signInButtonColour" type="signin" onClick={(event) => onSubmit(event)}>
          Sign In
        </Button>
        </div>
      </Form>
      <Button variant="btn btn-outline-primary" style={{marginTop: '0.8rem'}}  onClick={(event) => onRegister(event)}>Sign Up</Button>
	  <span class="error text-danger sign-up-error-message">{errorMessage}</span>
    </div>
  );
};

export default SignInForm;
