import React from 'react';
import './CSS/loading.css';
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Form } from 'react-bootstrap';

let targetEmissionRDS = 0;


//SETTING DEFAULT DISPLAY DATES TO LAST 6 MONTHS
var date = new Date();
date.setMonth(date.getMonth() - 3);
var start_date = date.toISOString().substring(0,10);

var date2 = new Date();
date2.setMonth(date2.getMonth());
var end_date = date2.toISOString().substring(0,10);


//fixed furthest dates available
let firstDate = new Date();
let lastDate = new Date();

//INITIALISING OF GLOBAL VARIABLES
var graphData, monthlyData  = ""
var targetEmission = [];
var monthlyLabels = [];
var monthlyAverages = [];
let totalEmissions, totalEmissionsDis = 0;
var pointOn = 0.8;
let serviceData;
let unit = 'g';

//MONTH NAMES USED TO DISPLAY CORRECT ORDER IN MONTHLY AVERAGE GRAPH
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

//SETTING AXIS LABELS ON GRAPHS
const graphOptions = {
	scales: {
			  y: {
				  title: {
					  display:true,
					  text:"Emissions (grams CO2)",
					  font: {
						  size: 14
					  }
				  }
			  },
			  x: {
				  title: {
					  display:true,
					  text:"Date",
					  font: {
						  size: 14
					  }
				  }
			  }
		}
};


class services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disView: 'emissionDisplay',
            graphType: 'line'
        };
        this.processData = this.processData.bind(this);
        //SETTING serviceData TO CORRESPONDING SERVICE METRIC 
        serviceData = this.props.state.RDS;
        //BELOW THE DAILY AVERAGE EMISSION IS SET AS AN INITIAL TARGET LEVEL
        totalEmissions=0;    
            for (let i=0;i<355;i++) {
                totalEmissions+=serviceData['emissions'][i];
            }
            totalEmissions = totalEmissions.toFixed(2);
            targetEmissionRDS = (totalEmissions/355).toFixed(2);
        this.unitCheck();
        this.processData();
    }

    unitCheck() {
        if(totalEmissions>1000 && totalEmissions < 1000000) {
            totalEmissionsDis = (totalEmissions/1000).toFixed(2);
            unit = 'kg'
        } else if(totalEmissions>1000000) {
            totalEmissionsDis = (totalEmissions/1000000).toFixed(2);
            unit = 'tonne'
        } else {
            totalEmissionsDis = totalEmissions;
            unit = 'g';
        }
    }
    
    

    processData() { 
        firstDate = serviceData['dates'][0];
        lastDate = serviceData['dates'][355];
        //USING SLICE WITH INDEXOF TO GET A REDUCED ARRAY OF THE SPECIFIED DATE RANGE FROM USER
        let monthSearchStart = (serviceData['dates'].indexOf(start_date));
        let monthSearchEnd = (serviceData['dates'].indexOf(end_date));
        let emissionValues = serviceData["emissions"].slice(monthSearchStart, monthSearchEnd);
        let emissionDates = serviceData["dates"].slice(monthSearchStart, monthSearchEnd);

        //SETTING (or) UPDATING targetEmission DATA WITH TARGET VALUE
        targetEmission = [];
        for (let i=0;i<serviceData['emissions'].length;i++){
            targetEmission.push(targetEmissionRDS);
        }   

        //POPULATING RESPONSIVE GRAPH WHICH CAN THEN BE CHANGED DEPENDANT ON THE DATE SELECTION MADE
        graphData = {
            labels: emissionDates,
            datasets: [{
                label: "RDS Emissions",
                data: emissionValues,
                labelColor: '#11146111',
                borderColor: "#dfd111",
                pointBorderColor: "#111111",
                pointBackgroundColor: "#FFFFFF",
                backgroundColor: "#dfd111",
                pointRadius: pointOn,
                tension: 0.4
            },
            {
                label: "Target",
                data: targetEmission,
                labelColor: 'red',
                borderColor: "red",
                pointBorderColor: "#111111",
                pointBackgroundColor: "#FFFFFF",
                backgroundColor: "red",
                pointRadius:0
            }
            ],  
        };

        //CLEARING LABELS AND AVERAGES
        monthlyLabels = [];
        monthlyAverages = [];

        for (let i=11;i>-1;i--) {
            //GET REACTIVE START END DATES OF MONTHS BEING SEARCHED
            var date3 = new Date();
            //WHERE DATE IS FIRST MONTH - SINCE WORKING WITH 355 DATA ENTRIES NOT 365 THE DATES MUST THEREFORE BE ALTERED ON FIRST MONTHLY CHECK
            if (i===11) {
                date3.setDate(date3.getDate() -355);
                var startMonth = date3.toISOString().substring(0,10);
            }else {
                date3.setMonth(date3.getMonth() -(1+i));
                var startMonth = date3.toISOString().substring(0,10);
            }
            var date4 = new Date();
            date4.setMonth(date4.getMonth() -(i));
            var endMonth = date4.toISOString().substring(0,10);
            //FILLING OUT THE MONTH NAMES IN ORDER OF DATA ON DISPLAY
            monthlyLabels.push(monthNames[date3.getMonth()]);

            //LOADING RESPECTIVE MONTH DATA INTO temp VARIABLE
            let temp = (serviceData['emissions'].slice(serviceData['dates'].indexOf(startMonth), serviceData['dates'].indexOf(endMonth)));
            let tempTotal = 0;
            let average = 0;
            for (let j=0;j<temp.length;j++) {
                tempTotal += temp[j];                
            }
            average = tempTotal/temp.length;
            monthlyAverages.push(average);
        };            

        //POPULATE MONTHLY AVERAGE GRAPH
        monthlyData = {
            labels: monthlyLabels,
            datasets: [{
                label: "Monthly Averages",
                data: monthlyAverages,
                labelColor: '#11146111',
                borderColor: "#4DC543",
                pointBorderColor: "#111111",
                pointBackgroundColor: "#FFFFFF",
                backgroundColor: "#4DC543",
                tension: 0.4
            }
            ],    
        };
        //NOW ALL GRAPHS ARE POPULATED THE DISPLAY IS SET TO emissionDisplay
        this.setState({
            disView: 'emissionDisplay'
        });

        //ATTEMPT TO SET HTML DATE INPUT TEXT TO CORRESPONDING DATE REQUEST
        try {
            document.getElementById('startDate').value = 'startDate';
            document.getElementById('endDate').value = 'endDate';
        } catch {
            //do nothing
        }
    }

    handleFormSubmitDates() {      
        //validating the user input - ensuring it is within the parameters (within a year of the date of request)
        //DATES ARE OUTWITH BOTH DATE RANGE
        if ((firstDate > document.getElementById('startDate').value) && (lastDate < document.getElementById('endDate').value)) {
            document.getElementById('startError').innerHTML = "*Start date must be within the last year*";
            document.getElementById('endError').innerHTML = "*End date must not be after the present day*";
            document.getElementById('startDate').value = start_date;
            document.getElementById('endDate').value = end_date;
        //START DATE IS AFTER CURRENT DAY
        } else if (firstDate > document.getElementById('endDate').value){
            document.getElementById('endError').innerHTML = "";
            document.getElementById('startError').innerHTML = "*Start date cannot be beyond present day.*";
            document.getElementById('startDate').value = start_date;
        //START DATE IS TOO FAR BACK - DATA NO LONGER IS AVAILABLE
        }else if (firstDate > document.getElementById('startDate').value) {
            document.getElementById('endError').innerHTML = "";
            document.getElementById('startError').innerHTML = "*Start date must be within the last year*";
            document.getElementById('startDate').value = start_date;
        //END DATE IS BEYOND PRESENT DAY
        } else if (lastDate < document.getElementById('endDate').value){
            document.getElementById('startError').innerHTML = "";
            document.getElementById('endError').innerHTML = "*End date must not be after the present day*";
            document.getElementById('endDate').value = end_date;
        //START DATE IS AFTER END DATE
        } else if (document.getElementById('endDate').value < document.getElementById('startDate').value){
            document.getElementById('startError').innerHTML = "";
            document.getElementById('endError').innerHTML = "*Cannot contradict time..*";
            document.getElementById('endDate').value = end_date;
        //DATES ARE ACCEPTABLE
        }else {
            document.getElementById('startError').innerHTML = "";
            document.getElementById('endError').innerHTML = "";
            start_date = document.getElementById('startDate').value;
            end_date = document.getElementById('endDate').value;
        }
        //DATES ARE NOW REQUESTED TO BE UPDATED
        this.processData();
    }

    handleFormSubmitDisplay() {
        //CHANGING TO BAR DISPLAY
        if (document.getElementById('graphType').value === 'bar') {  
            this.setState({
                disView: 'load',
                graphType: 'bar'
            });
            this.processData()
        
        //ALLOWS CHANGE BACK TO LINE DISPLAY
        } else if (document.getElementById('graphType').value === 'line') {
            this.setState({
                disView: 'load',
                graphType: 'line'
            });
            this.processData()
        }
    } 

    //SETTING THE UPDATED TARGET VALUE INTO ARRAY FORMAT WHICH IS THE SAME LENGTH/SIZE AS THE DISPLAYED DATA ARRAY AND USED AS A DATASET IN THE GRAPH
    handleUpdateTarget(){
        if (document.getElementById('targetEmissionRDS').value > 0) {
            //TARGET IS SET TO NEW INPUT FROM UI
            targetEmissionRDS = document.getElementById('targetEmissionRDS').value;
        }
        this.processData();
    }


    render() {
        if (this.state.disView === 'emissionDisplay' && this.state.graphType === 'line') {
            return (
                <main class="dashboard-container">
                    <div class="dashboard-main-title">
						<h1 class="fw-light text-center"><b>{this.props.state.serviceChoice} Overview.</b></h1>
                        <h1 class="lead text-center" style={{textAlign: 'center',marginTop:'0.5rem'}}>Annual Total: <b>{totalEmissionsDis} {unit}/CO<sub>2</sub></b></h1>
					</div>
                <div class="card">
						<h5 class='dashboard-metric-title' style={{textAlign:'center'}}>Overall {this.props.state.serviceChoice} Emission Data</h5>
						<div class="overall-container"> 
							<div class="dashboard-overall-chart">
								<article >
									<Line data={graphData} options={graphOptions} width={"100%"} height={"100%"} class="overall-aspect-ratio"/>
								</article>
							</div>
                            <div class="dashboard-metrics" id='dashboard-graphOptions'>
                                <div class="card shadow-sm graph-constraints">
                                    <div class="card-body">
                                        <h5>Update Target Value: </h5>
                                        <p>Current Target: <b>{targetEmissionRDS}g/CO<sub>2</sub></b></p>
                                        <label>Target Emission: </label>
                                        <div class="input-group input-group-sm mb-3">
                                        <input placeholder={targetEmissionRDS}  type='text' inputMode='numeric' pattern="[0-9]*" id='targetEmissionRDS' class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                                            <button class="btn btn-outline-success" type="button" id="button-addon2" onClick={event => {this.handleUpdateTarget(event)}}>Update</button>
                                        </div>
                                        <div class="input-group-prepend">
                                            <label>Data display: </label>
                                            <div class="input-group input-group-sm mb-3">
                                                <Form.Select id='graphType' >
                                                    <option value='line'>Line</option>
                                                    <option value='bar'>Bar</option>
                                                </Form.Select> 
                                                <button type="button" class="btn btn-outline-success" onClick={event => {this.handleFormSubmitDisplay(event)}}>Update</button>
                                            </div>
                                        </div>                                    
                                    </div>
								</div>
                                <div class="card shadow-sm graph-constraints">
                                    <div class="card-body">
                                    <p>Data is available between,<br/> <b>{firstDate}</b> and <b>{lastDate}</b></p><hr />
                                        <div class="input-group input-group-sm mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-sm">Start:</span>
                                            </div>
                                            <input type='date' id='startDate' class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                                        </div>
                                        <p id="startError" color={"red"}></p>
                                        <div class="input-group input-group-sm mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-sm">End:</span>
                                            </div>
                                            <input type='date' id='endDate' class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                                        </div>
                                        <p id="endError" color={"red"}></p>   
                                        <button type="button" class="btn btn-outline-success px-4 me-sm-1 fw-bold" size='sm' onClick={event => {this.handleFormSubmitDates(event)}}>Submit</button>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>
                    <div class='card'>
                    <h5 class="dashboard-metric-title" style={{textAlign:'center'}}>Monthly Averages</h5><br/> 
                        <article>
                            <Line data={monthlyData} class="bd-placeholder-img card-img-top"  aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice"width={300}  options={graphOptions} />
                        </article><br/><br/>
                    </div>               
            </main>        
            )
        } else if (this.state.disView === 'emissionDisplay' && this.state.graphType === 'bar') {
            return (
                <main class="dashboard-container">
                    <div class="dashboard-main-title">
                        <h1 class="fw-light text-center"><b>{this.props.state.serviceChoice} Overview.</b></h1>
                        <h1 class="lead text-center" style={{textAlign: 'center',marginTop:'0.5rem'}}>Annual Total: <b>{totalEmissionsDis} {unit}/CO<sub>2</sub></b></h1>
                    </div>
                    <div class="card">
                            <h5 class='dashboard-metric-title' style={{textAlign:'center'}}>Overall {this.props.state.serviceChoice} Emission Data</h5>
                            <div class="overall-container">  
							<div class="dashboard-overall-chart">
								<article >
									<Bar data={graphData} options={graphOptions} width={"100%"} height={"100%"} class="overall-aspect-ratio"/>
								</article>
							</div>
                            <div class="dashboard-metrics" id='dashboard-graphOptions'>
                                <div class="card shadow-sm graph-constraints">
                                    <div class="card-body">
                                        <h5>Update Target Value: </h5>
                                        <p>Current Target: <b>{targetEmissionRDS}g/CO<sub>2</sub></b></p>
                                        <label>Target Emission: </label>
                                        <div class="input-group input-group-sm mb-3">
                                        <input placeholder={targetEmissionRDS}  type='text' inputMode='numeric' pattern="[0-9]*" id='targetEmissionRDS' class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                                            <button class="btn btn-outline-success" type="button" id="button-addon2" onClick={event => {this.handleUpdateTarget(event)}}>Update</button>
                                        </div>
                                        <div class="input-group-prepend">
                                            <label>Data display: </label>
                                            <div class="input-group input-group-sm mb-3">
                                                <Form.Select id='graphType' >
                                                    <option value='line'>Line</option>
                                                    <option value='bar'>Bar</option>
                                                </Form.Select> 
                                                <button type="button" class="btn btn-outline-success" onClick={event => {this.handleFormSubmitDisplay(event)}}>Update</button>
                                            </div>
                                        </div>                                    
                                    </div>
								</div>
                                <div class="card shadow-sm graph-constraints">
                                    <div class="card-body">
                                    <p>Data is available between,<br/> <b>{firstDate}</b> and <b>{lastDate}</b></p><hr />
                                        <div class="input-group input-group-sm mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-sm">Start:</span>
                                            </div>
                                            <input type='date' id='startDate' class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                                        </div>
                                        <p id="startError" color={"red"}></p>
                                        <div class="input-group input-group-sm mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-sm">End:</span>
                                            </div>
                                            <input type='date' id='endDate' class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
                                        </div>
                                        <p id="endError" color={"red"}></p>   
                                        <button type="button" class="btn btn-outline-success px-4 me-sm-1 fw-bold" size='sm' onClick={event => {this.handleFormSubmitDates(event)}}>Submit</button>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>
                    <div class='card'>
                    <h5 class="dashboard-metric-title" style={{textAlign:'center'}}>Monthly Averages</h5><br/> 
                        <article>
                            <Bar data={monthlyData} class="bd-placeholder-img card-img-top"  aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice"width={300}  options={graphOptions} />
                        </article><br/><br/>
                    </div>               
            </main>        
            )
        }else if (this.state.disView === 'load') {
            return (
                <div class={"Loading"}>
                <p>Loading</p>
                    <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div> 
                </div>
            )
        }
    } 
}
export default services;
