import React from "react";
import VerifyForm from "./VerifyForm"

class VerifyUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disView: "verifyUser"
        }

    }
    render() {
		return (
			<VerifyForm update={this.props.update} state={this.state} checkUser={this.props.checkUser}/>
		);
    }
}
export default VerifyUser;
