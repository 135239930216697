import React, { useState } from "react";
import UserPool from "../UserPool";
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

const validation_url = 'https://api.climatece.co.uk/validation-test?token=7a197192-a2d1-400b-9de2-dfcf735d48b2'

const SignUpForm = ({ update, state }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [access_key, setAccessKey] = useState("");
  const [secret_key, setSecretKey] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();

    let attributeList = [];

    // Changet this to dynamic
    let accessKey = {
      "Name": "custom:access_key",
      "Value": access_key,
    };

    let secretKey = {
      "Name": "custom:secret_key",
      "Value": secret_key,
    };

    const data = { access_key: access_key, secret_key: secret_key };

    var attributeAccessKey = new CognitoUserAttribute(accessKey);
    var attributeSecretKey = new CognitoUserAttribute(secretKey);

    attributeList.push(attributeAccessKey);
    attributeList.push(attributeSecretKey);

    fetch(validation_url, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        if(data.status == 'success'){
          UserPool.signUp(email, password, attributeList, null, (err, data) => {
            if (err) {
              setErrorMessage(err.message);
            }
            // Run Update backend database here with the acces keys (for future reduction in latency)
            else {
              localStorage.setItem("verifyEmail", email);
              update("verifyUser", false);
            }
          });
        }else if(data.status == 'unsuccessful') {
          setErrorMessage(data.message);
        }else {
          setErrorMessage("Validation server error.");
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage(error.message);
      });
  };

  const showPwd = (event) => {
    event.preventDefault();
    var x = document.getElementById("passwordEntry");
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
  }

  return (
    <div class="sign-up-container">
      <h1>Sign Up</h1>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" value={email} onChange={(event) => setEmail(event.target.value)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control id='passwordEntry' type="password" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
          <Form.Text className="text-muted">
          
            Password needs to have at least 8 characters, 1 capital letter, 1 number and 1 special character.
          </Form.Text>
        </Form.Group>
        <div class="text-center"><p><input id='passwordShow' type="checkbox" onClick={(event) => showPwd(event)}/> Show Password</p></div>
        <Form.Group className="mb-3" controlId="formBasicAccessKey">
          <Form.Label>AWS Access Key</Form.Label>
          <Form.Control type="access_key" placeholder="Enter AWS Access Key" value={access_key} onChange={(event) => setAccessKey(event.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicSecretKey">
          <Form.Label>AWS Secret Key</Form.Label>
          <Form.Control type="password" placeholder="Enter AWS Secret Key" value={secret_key} onChange={(event) => setSecretKey(event.target.value)} />
          <Form.Text className="text-muted">
            We'll never share your details with anyone else.
          </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit" onClick={(event) => onSubmit(event)}>
          Sign Up
        </Button>
      </Form>
      <span class="error text-danger sign-up-error-message">{errorMessage}</span>
    </div>
  );
};

export default SignUpForm;
