import React from 'react';

export default function Quickstart() {
  return (
    <div class="quickstart-container">
			<div class="card quickstart-content">
				<img src="https://cliply.co/wp-content/uploads/2021/02/392102850_EARTH_EMOJI_400px.gif" style={{width:"100px",height:"auto",marginLeft:"auto",marginRight:"auto",display:"block"}} alt=""/>
				<h1 class="text-center" style={{margin:"0 0 0 0"}}>Quickstart</h1>
				<p class="text-center" style={{marginBottom:"1rem"}}><b>Get started with using the app in as little time as possible.</b></p>
				<p style={{marginBottom:"2rem"}}>In order to use the app, you will need the access key and secret key of a AWS user account with read-only Cost Explorer permissions. Alternatively, you can make a demo account to try out the application with sample data.</p>
				<h3 class="text-center">Make a Demo Account</h3>
				<p>If you do not have access to a valid AWS account and would like to try out the features of ClimateCE it is possible to create a demo account. To create a new account go to "Sign In" on the navigation bar followed by "Sign Up" to make a new account. To create a new account enter your email and password. In order to create </p>
				<img src="https://i.imgur.com/Dqdw7K2.png" class="quickstart-img"  alt=""/>
				<p>After pressing "Sign Up" you will be prompted to verify your account with the verification code sent to you via email.</p>
				<img src="https://i.imgur.com/qElZhXn.png" class="quickstart-img" alt=""/>
				<p style={{marginBottom:"2rem"}}>When the account is verified successfully you will be able to Sign in and view all of the different features of the app.</p>
				<h3 class="text-center">Make a Real Account</h3>
				<p>In order to make a real account you need access to a AWS account with the ability to create a new user.</p>
				<p>To start, go to your AWS Console and open IAM</p>
				<img class="quickstart-img" src="https://i.imgur.com/2zG4d28.png" alt=""/>
				<p>Next, go to the sidebar on the left and press "Users" followed by the "Add Users" button. Enter a suitable username and select the first tickbox that says "Access key - Programmatic access" and press Next.</p>
				<p>On the permissions page, press "Create Group" and then "Create Policy" and create a policy with Cost Explorer Read only permissions as shown below.</p>
				<img class="quickstart-img" src="https://i.imgur.com/jd6TPpa.png" alt=""/>
				<p>Press Next until you get to the "Review" page give the policy a name, press "Create Policy" and go back to the previous page.</p>
				<p>Now on your Add User screen, create a group with the policy you just created by searching it in the Policy Search</p>
				<img class="quickstart-img" src="https://i.imgur.com/k3S5QnO.png" alt=""/>
				<p>Next press "Create Group" followed by the "Next" button and then the "Create User" button. You should now see the access key and secret key you need to sign up for a user account on climatece.co.uk.</p>
				<img class="quickstart-img" src="https://i.imgur.com/y7axguy.png" alt=""/>
				<p>To register an account, follow the steps of "Make a Demo account" but substituting "demo" with your corresponding access keys.</p>
				<p class="text-center">Feel free to visit our <a href="/docs/user_guide.pdf">User Guide</a> for more information about the usage of the app.</p>
				<br/>
				<p><b>Note: Do NOT use access keys with any more privilidges privileges than mentioned. Although the access keys are stored securly, ClimateCE is a work in progress and does not take responsibility for any leaked access keys.</b></p>
			</div>
    </div>
  );
}
