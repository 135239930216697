import React from 'react';
import './CSS/loading.css';
import MapChart from "./Map";

  
class Global extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disView: 'global'
        };
        localStorage.setItem('cceRefresh', 'Global');
    }

    //RENDERING HTML
    render () {                                 
        if (this.state.disView === 'global') {
            return (
                <main class="dashboard-container" style={{marginTop:"5rem!important"}}>     
                    <section class=" text-center container mt-5">
                      <div >
                            <div class="col-lg-6 col-md-8 mx-auto">
                                <h1 class="fw-light" style={{paddingBottom:"0px"}}>Regional Usage of AWS Services</h1>
                            </div>
                        </div>
                    </section>
                    <div class="overall-aspect-ratio">
                        <div class="card shadow-lg ">
                            <div class="card-body">
                                <div class="overall-monthly-emissions" >
                                    <MapChart state={this.props.state} />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
          )
      } else if (this.state.disView === 'load') {
          return (
              <div class={"Loading"}>
              <p>Loading</p>
                  <div class="spinner-border" role="status">
                      <span class="sr-only"> </span>
                  </div> 
              </div>
          )
      }
  }
}

export default Global;
